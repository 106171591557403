import http from "./httpService";
const apiEndpoint = "/widget";

export function addWidget(projectId) {
  return http.post(apiEndpoint + "/" + projectId, {
  });
}


export function allWidgetDetails(projectId) {
  return http.get(apiEndpoint + "/" + projectId);
}


export function updateWidget(projectId, req) {
  return http.put(apiEndpoint + "/" + projectId, req);
}
