import React, { useState, useEffect } from "react";
import * as customActionService from "../../../services/customActions";
import EditSMSTrigger from "./EditSMSTrigger";
import ExistingSMSTriggers from "./ExistingSMSTriggers";
import EnableCustomAction from "./EnableCustomAction";

function Configuration({ projects, activeProject,setTabState, settings }) {
  const [allSMSTriggers, setAllSMSTriggers] = useState({});

  const [formState, setFormState] = useState("view");
  const [item, setItem] = useState({});
  const [reload, setReload] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await customActionService.getAllCustomActions(
          projects[activeProject]._id
        );
        const smsTriggers = response.data.customActions.filter(action => action.actionFunction === "SMS");

        setAllSMSTriggers(smsTriggers); 
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [projects, activeProject, reload]); // Adding dependencies



  if (formState === "view")
    return (
      <div>
        <EnableCustomAction
            projects={projects}
            activeProject={activeProject}
            allSMSTriggers={allSMSTriggers}
            setItem={setItem}
            setFormState={setFormState}
            item={item}
            setReload={setReload}
            reload={reload}
            settings={settings}
        />
        <ExistingSMSTriggers
          projects={projects}
          activeProject={activeProject}
          allSMSTriggers={allSMSTriggers}
          setItem={setItem}
          setFormState={setFormState}
          item={item}
          setReload={setReload}
          reload={reload}
        />
      </div>
    );

  if (formState === "edit")
    return (
      <div>
        <EditSMSTrigger
          item={item}
          projects={projects}
          activeProject={activeProject}
          setFormState={setFormState}
          reload={reload}
          setReload={setReload}        
        />
      </div>
    );
}

export default Configuration;
