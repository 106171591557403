import { useEffect } from "react";
import auth from "../services/authService";

function Logout(props) {
  useEffect(() => {

    localStorage.removeItem('activeProject');
    auth.logout();
    window.location = "/login";
  }, []); 

  return null; 
}

export default Logout;
