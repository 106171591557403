import http from "./httpService";
const apiEndpoint = "/tpauth";

export function checkToken(projectId, code, location, accountsServer, type) {
  return http.post(apiEndpoint + "/thirdpartyauth" + "/" + projectId, {
    code,
    location,
    accountsServer,
    type,
  });
}

export function thirdpartyUpdate(settings, projectId) {
  return http.put(`${apiEndpoint}/updateThirdPartyData/${projectId}`, settings);
}

export function getthirdpartyData(projectId) {
  return http.get(`${apiEndpoint}/getThirdPartyData/${projectId}`);
}


