import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../common/table";
import { Button } from "../../../common/button";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../../../common/dropdown";
import { EllipsisHorizontalIcon } from "@heroicons/react/16/solid";
import {
  Alert,
  AlertActions,
  AlertBody,
  AlertDescription,
  AlertTitle,
} from "../../../common/alert";
import * as humanAgentService from "../../../services/humanAgentService";
import { message } from "antd";

function ExistingAgents({
  projects,
  activeProject,
  humanagentsInfo,
  setItem,
  setFormState,
  item,
  setReload,
  reload,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleEdit = (item) => {
    setFormState("edit");
    setItem(item);
  };

  const showDeleteModal = (item) => {
    setIsOpen(true);
    setItem(item);
  };

  const handleDeleteConfirmation = async () => {
    try {
      const response = await humanAgentService.deleteHumanAgent(
        projects[activeProject]._id,
        item._id
      );

      if (response.status === 200) {
        message.success("Agent successfully deleted");
        setIsOpen(false);
        setReload(!reload);
        setFormState("view")
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error("Request failed");
    }
  };

  return (
    <div>
      <div className="divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-10">
        <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
          Existing Human Agents
        </div>
        <div>
          {humanagentsInfo &&
            humanagentsInfo.humanAgents &&
            humanagentsInfo.humanAgents.length > 0 && (
              <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)] p-5">
                <TableHead>
                  <TableRow>
                    <TableHeader>Agent Name</TableHeader>
                    <TableHeader>Phone Number</TableHeader>
                    <TableHeader>Keyword</TableHeader>
                    <TableHeader>Action</TableHeader>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {humanagentsInfo &&
                    humanagentsInfo.humanAgents &&
                    humanagentsInfo.humanAgents.length > 0 &&
                    humanagentsInfo.humanAgents.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell className="font-medium">
                          {item.name}
                        </TableCell>
                        <TableCell>{item.phoneNumber}</TableCell>
                        <TableCell>{item.searchTerm}</TableCell>

                        <TableCell>
                          <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                            <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                              <Dropdown>
                                <DropdownButton plain aria-label="More options">
                                  <EllipsisHorizontalIcon />
                                </DropdownButton>
                                <DropdownMenu anchor="bottom end">
                                  <DropdownItem
                                    onClick={() => showDeleteModal(item)}
                                  >
                                    Delete
                                  </DropdownItem>

                                  <DropdownItem
                                    onClick={() => handleEdit(item)}
                                  >
                                    Edit
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          <Alert open={isOpen} onClose={() => setIsOpen(false)} size="sm">
            <AlertTitle>Delete Agent</AlertTitle>
            <AlertDescription>
              Once deleted, all agent information will be removed from our
              system, and no further calls can be transferred to this agent.
            </AlertDescription>
            <AlertBody style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              ></div>
            </AlertBody>
            <AlertActions>
              <Button plain onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
              <Button onClick={() => handleDeleteConfirmation()}>OK</Button>
            </AlertActions>
          </Alert>
        </div>
      </div>
    </div>
  );
}

export default ExistingAgents;
