import http from "./httpService";
// import { apiUrl } from "../config.json";

// const apiEndpoint = apiUrl + "/users";
const apiEndpoint =  "/user";

export function register(user) {
  return http.post(apiEndpoint, {
    email: user.email,
    password:user.password,
  });
}


export function updateUser(req) {
  return http.put(apiEndpoint, req);
}




export function verifyUser(req) {
  return http.put(apiEndpoint + "/verify-email", { token: req });
}

export function acceptInvite(token, password) {
  return http.put(apiEndpoint + "/invite-email", {
    token: token,
    password: password,
  });
}
