import React from 'react';

// Correcting the function to accept a props object
function Search({ value, placeholder, onSearch, handleBackspaceKeyPress }) {
    return (
        <div className="flex border-2 rounded rounded-2xl">
            <input 
                onChange={(e) => onSearch(e.target.value)}
                onKeyDown={handleBackspaceKeyPress}
                placeholder={placeholder}
                type="text" 
                className="px-4 py-2 w-full rounded-2xl" 
                value={value}
                name="query"
            />
        </div>
    );
}

export default Search;
