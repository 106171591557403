import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import lottie from "lottie-web";
import success from "../animation/succesful.json";
import { Button } from "../common/button";


function PaymentSuccess(props) {
  const container = useRef(null);

  // Using navigate if needed later, since it's declared
  const navigate = useNavigate();

  useEffect(() => {
    let anim;
    if (container.current) {
      anim = lottie.loadAnimation({
        container: container.current, 
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: success, 
      });
    }

    return () => {
      if (anim) {
        anim.destroy();
      }
    };
  }, []); //

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div ref={container}></div>
      <Button onClick={() => navigate("/")}>Take me home</Button>
    </div>
  );
}

export default PaymentSuccess;
