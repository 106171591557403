import React from "react";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../common/dropdown";
import { ChevronDownIcon } from "@heroicons/react/16/solid";

function ProjectSelection({ projects, activeProject, setActiveProject }) {
  // Assuming props.statuses is an array of objects with properties 'value' and 'label'
  //   const { statuses } = props;

  const handleClick = (index) => {
    setActiveProject(index);

    // window.location = "/";
  };

  return (
    <div className="pr-10">
      <Dropdown>
        <DropdownButton outline>
          {projects && projects[activeProject] && projects[activeProject].name}
          <ChevronDownIcon />
        </DropdownButton>
        <DropdownMenu>
          {projects &&
            projects.map((project, index) => (
              <DropdownItem
                onClick={() => handleClick(index)}
                key={index}
              >
                {project.name}
              </DropdownItem>
            ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default ProjectSelection;
