import React, { useState, useEffect } from "react";
import QuestionAddingBlock from "./QuestionAddingBlock";
import Default from "./Default";
import KeywordMatching from "./KeywordMatching";

function AdvancedOptions({
  projects,
  activeProject,
  settings,
  reload,
  setReload,
}) {
  const [mainMenuState, setMainMenuState] = useState("Default");

  const handleAddQuestionCustomize = () => {
    setMainMenuState("QuestionAnswerBlock");
  };

  const handleAddKeywordTriggers=()=>{
    setMainMenuState("handleAddKeywordTriggers");
  }

  return (
    <div>
      {mainMenuState === "Default" && (
        <Default
          projects={projects}
          activeProject={activeProject}
          settings={settings}
          handleAddQuestionCustomize={handleAddQuestionCustomize}
          handleAddKeywordTriggers={handleAddKeywordTriggers}
          reload={reload}
          setReload={setReload}
        />
      )}
      {mainMenuState === "QuestionAnswerBlock" && (
        <QuestionAddingBlock
          projects={projects}
          activeProject={activeProject}
          settings={settings}
          reload={reload}
          setReload={setReload}
          setMainMenuState={setMainMenuState}
        />
      )}

{mainMenuState === "handleAddKeywordTriggers" && (
        <KeywordMatching
          projects={projects}
          activeProject={activeProject}
          settings={settings}
          reload={reload}
          setReload={setReload}
          setMainMenuState={setMainMenuState}
        />
      )}
    </div>
  );
}

export default AdvancedOptions;
