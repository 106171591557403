import http from "./httpService";
const apiEndpoint = "/v1/embedChat";

export function createDomainIntegration(data) {
    return http.post(apiEndpoint + "/createDomainIntegration/" ,data )
  }


  export function whitelistDomain(data) {
    return http.post(apiEndpoint + "/whitelistDomain" ,data )
  }
    
  