import React, { useState } from "react";
import { Button } from "../../common/button";
import zohoCRM from "../../images/zohoCRM.svg";
import GHL from "../../images/GHL.png";
import Joi from "joi-browser";
import Form from "../../components/Form";
import * as thirdPartyServices from "../../services/thirdpartyService";
import { message } from "antd";


function OnceHub({ activeProject, projects }) {
  const [formField] = useState({schedulingLink:"",onceHubAPIKey: ""});

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const template = [
    {
      name: "schedulingLink",
      label: "Scheduling Link",
      type: "text",
      style: "",
    },
    {
      name: "onceHubAPIKey",
      label: "Your API key (optional)",
      type: "password",
      style: "",
    },
  ];

  const buttonInfo = {
    label: "Connect ",
    style: "entire-width",
  };

  const schema = {
    schedulingLink:Joi.string()
    .max(5000)
    .required()
    .label("schedulingLink"),
    onceHubAPIKey: Joi.string()
      .max(5000)
      .allow(null,"")
      .label("Your Oncehub API key"),
  };

  const handleTwilioConnection = async (data) => {
    let calendarSettings = {
      calendarSettings: {
        calendarProvider: "OnceHub",
        onceHubCalendarSettings: {
          onceHubAPIKey: data.onceHubAPIKey,
          schedulingLink:data.schedulingLink
        },
      },
    };

    try {
      const response = await thirdPartyServices.thirdpartyUpdate(
        calendarSettings,
        projects[activeProject]._id
      );
      if (response.status === 200) {
        message.success("Oncehub connected");
        setLoading(false);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false); // Ensure setLoading is defined and used correctly
        message.error(ex.response.data.msg);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <div className="flex flex-col items-center bg-white mt-10 ">
        <div className="w-full max-w-md px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-white shadow rounded-2xl">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Oncehub
          </h3>
          <div className="mt-2 text-sm text-gray-500">
            <p>Bring your Oncehub API key to connect your calendar.</p>
          </div>

          <div>
            <Form
              errorMessage={errorMessage}
              inputField={formField}
              template={template}
              schema={schema}
              buttonInfo={buttonInfo}
              onSubmit={handleTwilioConnection}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnceHub;
