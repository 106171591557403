import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "../../components/Form";
import { Button } from "../../common/button";
import { message } from "antd";
import * as thirdPartyServices from "../../services/thirdpartyService";

function VoiceConfiguration({ projects, activeProject, settings }) {
  const [inputField, setInputField] = useState({
    // transcriptionLanguage: "",
    keywordsToBoost: "",
    // endpointingTime: 5,
    // utteranceEndTime: 2,
    // playHTVoiceId:"",
    // elevenlabsVoiceId:"",
    // interruptionThreshold:""
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (projects[activeProject]._id) {
          const response = await thirdPartyServices.getthirdpartyData(
            projects[activeProject]._id
          );

          let Keywords =
            response.data.transcriptionSettings.deepgramSettings
              .keywordsToBoost;

          setInputField({
            keywordsToBoost:
              response.data.transcriptionSettings.deepgramSettings
                .keywordsToBoost,
          });
        }
      } catch (error) {}
    };
    fetchData();
  }, [projects, activeProject]);

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const template = [
    // {
    //   name: "transcriptionLanguage",
    //   label: "Transcription Language",
    //   type: "select",
    //   options: [
    //     { id: 1, value: "bg", label: "Bulgarian" },
    //     { id: 2, value: "ca", label: "Catalan" },
    //     { id: 3, value: "cs", label: "Czech" },
    //     { id: 4, value: "da", label: "Danish" },
    //     { id: 5, value: "da-DK", label: "Danish (Denmark)" },
    //     { id: 6, value: "nl", label: "Dutch" },
    //     { id: 7, value: "en", label: "English" },
    //     { id: 8, value: "en-US", label: "English (US)" },
    //     { id: 9, value: "en-AU", label: "English (Australia)" },
    //     { id: 10, value: "en-GB", label: "English (UK)" },
    //     { id: 11, value: "en-NZ", label: "English (New Zealand)" },
    //     { id: 12, value: "en-IN", label: "English (India)" },
    //     { id: 13, value: "et", label: "Estonian" },
    //     { id: 14, value: "fi", label: "Finnish" },
    //     { id: 15, value: "nl-BE", label: "Flemish" },
    //     { id: 16, value: "fr", label: "French" },
    //     { id: 17, value: "fr-CA", label: "French (Canada)" },
    //     { id: 18, value: "de", label: "German" },
    //     { id: 19, value: "de-CH", label: "German (Switzerland)" },
    //     { id: 20, value: "el", label: "Greek" },
    //     { id: 21, value: "hi", label: "Hindi" },
    //     { id: 22, value: "hi-Latn", label: "Hindi (Latin)" },
    //     { id: 23, value: "hu", label: "Hungarian" },
    //     { id: 24, value: "id", label: "Indonesian" },
    //     { id: 25, value: "it", label: "Italian" },
    //     { id: 26, value: "ja", label: "Japanese" },
    //     { id: 27, value: "ko", label: "Korean" },
    //     { id: 28, value: "ko-KR", label: "Korean (South Korea)" },
    //     { id: 29, value: "lv", label: "Latvian" },
    //     { id: 30, value: "lt", label: "Lithuanian" },
    //     { id: 31, value: "ms", label: "Malay" },
    //     { id: 32, value: "no", label: "Norwegian" },
    //     { id: 33, value: "pl", label: "Polish" },
    //     { id: 34, value: "pt", label: "Portuguese" },
    //     { id: 35, value: "pt-BR", label: "Portuguese (Brazil)" },
    //     { id: 36, value: "ro", label: "Romanian" },
    //     { id: 37, value: "ru", label: "Russian" },
    //     { id: 38, value: "sk", label: "Slovak" },
    //     { id: 39, value: "es", label: "Spanish" },
    //     { id: 40, value: "es-419", label: "Spanish (Latin America)" },
    //     { id: 41, value: "sv", label: "Swedish" },
    //     { id: 42, value: "sv-SE", label: "Swedish (Sweden)" },
    //     { id: 43, value: "th", label: "Thai" },
    //     { id: 44, value: "th-TH", label: "Thai (Thailand)" },
    //     { id: 45, value: "tr", label: "Turkish" },
    //     { id: 46, value: "uk", label: "Ukrainian" },
    //     { id: 47, value: "vi", label: "Vietnamese" },
    //   ],
    // },

    {
      name: "keywordsToBoost",
      label: "Keywords to boost",
      description: "Add keywords to emphasize them",
      type: "textArea",
      style: { height: "100px" },
    },

    // {
    //   name: "endpointingTime",
    //   label: "Ending time",
    //   //   description: "Only enable it if Auth is required",
    //   type: "slider",
    //   min:1,
    //   max:21,
    //   defaultValue:10
    // },

    // {
    //   name: "utteranceEndTime",
    //   label: "Utterance End time",
    //   type: "slider",
    //   min:1,
    //   max:21,
    //   defaultValue:10
    // },
    // {
    //   name: "interruptionThreshold",
    //   label: "Interruption Threshold",
    //   //   description: "Only enable it if Auth is required",
    //   type: "slider",
    //   min:1,
    //   max:21,
    //   defaultValue:10
    // },
    // {
    //     name: "playHTVoiceId",
    //     label: "PlayHT Voice ID",
    //     type: "text",
    //     style: { height: "100px" }
    //   },

    //   {
    //     name: "elevenlabsVoiceId",
    //     label: "Elevan Labs Voice ID",
    //     type: "text",
    //     style: { height: "100px" }
    //   },
  ];

  const buttonInfo = { label: "Save", style: "right-side" };

  const schema = {
    // transcriptionLanguage: Joi.string().required().label("Transcription Language"),
    keywordsToBoost: Joi.string().allow(null, "").label("Keywords to boost"),
    // endpointingTime: Joi.label("Ending time"),
    // utteranceEndTime: Joi.label("Utterance End time"),
    // interruptionThreshold: Joi.label("Interruption Threshold"),
    // elevenlabsVoiceId:Joi.string().allow(null,"").label("Eleven Labs Voice ID"),
    // playHTVoiceId: Joi.string().allow(null,"").label("PLayHT Voice ID")
  };

  const handleCreate = async (data) => {
    let transcriptionSettings = {
      transcriptionSettings: {
        deepgramSettings: {
          // transcriptionLanguage: data.transcriptionLanguage,
          keywordsToBoost: data.keywordsToBoost,
          // endpointingTime: data.endpointingTime,
        },
      },
    };

    try {
      const response = await thirdPartyServices.thirdpartyUpdate(
        transcriptionSettings,
        projects[activeProject]._id
      );
      if (response.status === 200) {
        message.success("New configuration succesfully saved");
        setLoading(false);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false); // Ensure setLoading is defined and used correctly
        message.error(ex.response.data.msg);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <Form
        key={JSON.stringify(inputField)}
        errorMessage={errorMessage}
        inputField={inputField}
        template={template}
        schema={schema}
        onSubmit={handleCreate}
        loading={loading}
        setLoading={setLoading}
        // getValue ={getInputValue}
        buttonInfo={buttonInfo}
      />
    </div>
  );
}

export default VoiceConfiguration;
