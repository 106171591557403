import React, { useState } from "react";
import UploadMultiple from "../../common/UploadMultiple";
import { message } from "antd";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { Button } from "../../common/button";
import * as knowledgeService from "../../services/knowledgebaseService";

function UploadFileForEmbedding({projects, activeProject}) {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (info) => {
    if (info.file.type === "text/csv") {
      message.error("CSV files are not allowed");
      return false;
    }

    setUploadedFiles((prevUploadedFiles) => [...prevUploadedFiles, info.file]);

    setVisibility(!visibility);
  };

  const handleVisibility = () => {
    setVisibility(!visibility);
  };

  const handleSubmit = async () => {
    setLoading(true);

    const formData = new FormData();
    uploadedFiles.forEach((file, index) => {
      formData.append("files", file);
      // Append the filePipeline field for each file
      // formData.append(`filePipeline_${index}`, 'your_file_pipeline_value_here');
    });

    if (uploadedFiles.length < 1) {
      setLoading(false);
      return message.error("Please upload atleast one file.");
    }

    // setFormState("uploading");

    const serviceMethod = knowledgeService.docsUpload;

    try {
      const response = await serviceMethod( projects[activeProject]._id, formData);
      if (response.status === 200) {
        setLoading(false);
        message.success({
          content: "Success. Upload complete",
          duration: 8,
        });
        setVisibility(false);
        // setFormState("fileupload");
        // setReload(!reload);
        setUploadedFiles([]);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false);
        message.error({
          content: ex.response.data.msg,
          duration: 8,
        });
        setVisibility(false);
        // setFormState("fileupload");
        setUploadedFiles([]);
        return;
      }

      if (
        ex.response &&
        ex.response.status === 500 
      ) {
        setLoading(false);
        message.error({
          content: "Please contact support immediately",
          duration: 8,
        });
        setVisibility(false);
        // setFormState("fileupload");
        setUploadedFiles([]);
        return;
      }
    }
  };

  const handleDelete = (index) => {
    const newArray = uploadedFiles.filter((_, i) => i !== index);
    setUploadedFiles(newArray);

    if (newArray.length < 1) setVisibility(false);
  };

  return (
    <div>
      {!visibility && (
        <div className="w-full">
          <UploadMultiple onChange={handleFileChange} />
          {uploadedFiles.length >= 1 && (
            <Button color="white" onClick={handleVisibility} className="w-full">
              Preview
            </Button>
          )}
        </div>
      )}

      {visibility && (
        <div>
          <div>
            {uploadedFiles.map((item, index) => (
              <div
                key={index}
                className="flex items-center justify-between overflow-hidden rounded-md bg-white px-6 py-4 shadow mb-2"
              >
                <p className="truncate flex-grow">{item.name}</p>
                <button
                  onClick={() => handleDelete(index)}
                  className="text-red-500 cursor-pointer"
                >
                  <RiDeleteBin6Fill />
                </button>
              </div>
            ))}
            <div className="flex w-full my-5">
              <Button
                outline
                className="w-1/2 mr-3 "
                onClick={handleVisibility}
              >
                Add more
              </Button>
              <Button
                className={`w-1/2 ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={loading}
                onClick={handleSubmit}
              >
                {loading ? "Uploading files..." : "Upload"}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UploadFileForEmbedding;
