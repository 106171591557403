import React, { useState, useEffect } from "react";
import { message } from "antd";
import Form from "../../../components/Form";
import Joi from "joi-browser";
import * as projectService from "../../../services/projectService";
import Drag from "../../../images/drag.svg";
import { Button } from "../../../common/button";
import MovableCards from "../../../components/MovableCards";
import {
  Alert,
  AlertActions,
  AlertBody,
  AlertDescription,
  AlertTitle,
} from "../../../common/alert";
import { Switch } from "../../../common/switch";

function QuestionAddingBlock({ projects, activeProject, setMainMenuState }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [formState, setFormState] = useState("");
  const [deleteItem, setDeleteItem] = useState("");
  const [indexForEdit, setIndexForEdit] = useState("add");
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);

  const [toggleValueforAdvancedOptions, setToggleValueforAdvancedOptions] =
    useState(false);
  let [isOpen, setIsOpen] = useState(false);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  let [posts, setPosts] = useState([]);

  const [reload, setReload] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await projectService.getPosts(
        projects[activeProject]._id
      );

      setPosts(data.workflowQuestions);
    };
    fetchData();
  }, [reload]);

  useEffect(() => {
    if (searchQuery) {
      let postsReceived = posts.filter((m) =>
        m.question.toLowerCase().includes(searchQuery.toLowerCase())
      );
      let postsPending = posts.filter(
        (m) => !m.question.toLowerCase().includes(searchQuery.toLowerCase())
      );

      postsPending.map((item) => (item.visibility = "no"));
      posts = [...postsReceived, ...postsPending];
      setPosts(posts);
    }
  }, [searchQuery]);

  const onDragEnd = async (props) => {
    const srcI = props.source.index;
   

    if (!props.destination) {
      message.error("Please drop the item only into the permitted area");
      return;
    }

    const desI = props.destination.index;

    const newPosts = Array.from(posts);
    const [movedItem] = newPosts.splice(srcI, 1);
    newPosts.splice(desI, 0, movedItem);
    setPosts(newPosts);

    try {
      const response = await projectService.updateProject(
        projects[activeProject]._id,
        {
          workflowQuestions: newPosts,
        }
      );
      if (response.status === 200) {
        message.success("Successfully rearranged");
        setReload(!reload);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data);
        return;
      }
    }
  };

  const handleAdd = () => {
    setIsOpen(false);
    setFormState("add");
    setInputField({
      question: "",
      field: "",
      variableName: "",
      condition: "",
      variableMatchValue: "",
    });
    //   setFormKey((prevKey) => prevKey + 1);
  };



  let [inputField, setInputField] = useState({
    question: "",
    // enableAdvancedOptions: "",
    field: "",
    variableName: "",
    condition: "",
    variableMatchValue: "",
  });

  useEffect(() => {}, [inputField]);

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  // let auth = useAuth();
  // const history = useHistory();

  const advancedOptions = [
    {
      name: "field",
      label: "Type of field",
      type: "select",
      options: [
        { id: 1, value: "attribute", label: "Attribute" },
        { id: 2, value: "keyword", label: "Keyword" },
        { id: 3, value: "metadata", label: "Metadata" },
        { id: 4, value: "api", label: "API" },
      ],
    },
    {
      name: "variableName",
      label: "Specify variable name",
      type: "text",
    },
    {
      name: "condition",
      label: "Specify condition",
      type: "select",
      options: [
        { id: 1, value: "is_missing", label: "is Missing" },
        { id: 2, value: "is_present", label: "is Present" },
      ],
    },
    {
      name: "variableMatchValue",
      label: "Variable match value",
      description:
        "Leave it blank if it can take any value; otherwise, specify it.",
      type: "text",
    },
  ];

  const template = [
    {
      name: "question",
      label: "Your question",
      type: "textArea",
      style: { width: "400px" },
    },
    ,
    // {
    //   name: "enableAdvancedOptions",
    //   label: "Conditional Visibility",
    //   type: "select",
    //   options: [
    //     { id: 1, value: false, label: "false" },
    //     { id: 2, value: true, label: "true" },
    //   ],
    // }
    ...(toggleValueforAdvancedOptions ? advancedOptions : []),
  ];

  const buttonInfo = {
    label: formState === "edit" ? "Save changes" : " Add a question",
    style: "",
  };

  const schema = {
    question: Joi.string().allow(null, "").max(500).label("Question"),
    field: Joi.string().allow(null, "").max(250).label("Field"),
    variableName: Joi.string().allow(null, "").max(30).label("variableName"),
    condition: Joi.string().allow(null, "").max(50).label("Condition"),
    // enableAdvancedOptions: Joi.boolean().label("Active state"),
    variableMatchValue: Joi.string()
      .allow(null, "")
      .max(5000)
      .label("Match value"),
  };

  const handleAddVisibility = () => {
    setIsOpen(true);
    setFormState("add");
    setInputField({
      question: "",
      field: "",
      variableName: "",
      condition: "",
      variableMatchValue: "",
    });
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleToggleValue = () => {
    setToggleValueforAdvancedOptions(!toggleValueforAdvancedOptions);
  };

  const handleQuestion = async (post) => {
    let newPosts;

    post.enableAdvancedOptions = toggleValueforAdvancedOptions;

    if (posts.length === 0) {
      newPosts = [post]; // Wrap post in an array when posts is empty
    } else {
      newPosts = [...posts, post]; // Append post to posts
    }

    try {
      const response = await projectService.updateProject(
        projects[activeProject]._id,
        { workflowQuestions: newPosts }
      );
      // const response = await customerService.addCustomer(projectId, data);

      if (response.status === 200) {
        setIsOpen(false);
        setLoading(false);
        message.success("New question added successfully");
        setReload(!reload);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false);
        message.error(ex.response.data.msg);
      }
    }
  };

  const handleEdit = async (item, index) => {
    setIsOpen(true);

    setFormState("edit");
    setIndexForEdit(index);
    // console.log("edititem", posts[index])
    setInputField({
      question: posts[index].question,
      field: posts[index].field,
      variableName: posts[index].variableName,
      condition: posts[index].condition,
      variableMatchValue: posts[index].variableMatchValue,
    });

    setToggleValueforAdvancedOptions(posts[index].enableAdvancedOptions);

    //   setFormKey(prevKey => prevKey + 1);
  };

  const handleEditQuestion = async (post) => {
    try {
      post.enableAdvancedOptions = toggleValueforAdvancedOptions;
      let newPosts = [...posts];
      newPosts[indexForEdit] = post;

      const response = await projectService.updateProject(
        projects[activeProject]._id,
        { workflowQuestions: newPosts }
      );

      if (response.status === 200) {
        setIsOpen(false);
        setLoading(false);
        message.success("Edit successful");
        setReload(!reload);
      }
    } catch (ex) {
      setLoading(false);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data.msg);
      } else {
        message.error("An unexpected error occurred");
      }
    }
  };

  const handleDelete = async () => {
    try {
      const updatedPosts = [...posts];
      updatedPosts.splice(deleteItem, 1);
      const response = await projectService.updateProject(
        projects[activeProject]._id,
        { workflowQuestions: updatedPosts }
      );
      if (response.status === 200) {
        message.success("Post deleted");
        setReload(!reload);
        setIsDeleteConfirmation(false);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data);
        return;
      }
    }
  };

  const onDelete = (keyId, index) => {
    setIsDeleteConfirmation(true);
    setIsDeleteConfirmation(true);
    setDeleteItem(index);
  };

  const handleBack = () => {
    setMainMenuState("Default");
  };

  return (
    <div className="relative divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-5">
      <div className="px-4 py-5 sm:px-6 flex items-center justify-between">
        <div className="flex items-center">
          <img src={Drag} alt="Drag Icon" className="mr-2 w-5 h-5 mr-10" />
          <span>
            You can add questions in separate block, set individuals conditions
            for each question <br /> and further drag and drop position of
            questions.
          </span>
        </div>
        <Button outline onClick={handleBack}>
          Go back
        </Button>
        <Button outline onClick={handleAddVisibility}>
          Add a question
        </Button>
      </div>
      <div className="overflow-hidden rounded-md bg-slate-50 px-6 py-4 shadow">
        <MovableCards
          type="questionaddingBlock"
          value={searchQuery}
          posts={posts}
          onSearch={handleSearch}
          onDragEnd={onDragEnd}
          // onConfirm={showDeleteModal}
          onEdit={handleEdit}
          // onChange={handleState}
          onDelete={onDelete}
        />
      </div>

      <Alert open={isDeleteConfirmation} onClose={setIsDeleteConfirmation}>
        <AlertTitle>Confirm Deletion</AlertTitle>
        <AlertDescription>
          Please confirm, do you want to delete this.
        </AlertDescription>
        <AlertActions>
          <Button plain onClick={() => setIsDeleteConfirmation(false)}>
            Cancel
          </Button>
          <Button onClick={() => handleDelete(deleteItem)}>Delete</Button>
        </AlertActions>
      </Alert>

      <Alert open={isOpen} onClose={() => setIsOpen(false)} size="sm">
        <AlertTitle className="flex justify-between items-center">
          <span>Create</span>
          <div className="flex items-center ">
            <Switch
              id={`checkbox-${indexForEdit}`}
              onChange={handleToggleValue}
              className="cursor-pointer"
              name="show_more_options"
              checked={toggleValueforAdvancedOptions}
            />
            <label className="ml-2 text-xs font-normal">Advanced</label>
          </div>
        </AlertTitle>

        <AlertBody style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Form
              key={JSON.stringify(inputField)}
              errorMessage={errorMessage}
              inputField={inputField}
              template={template}
              schema={schema}
              buttonInfo={{
                style: "",
                buttons: "2",
                primaryLabel: "Save changes",
                secondaryLabel: "Close",
              }}
              onSubmit={
                formState === "edit" ? handleEditQuestion : handleQuestion
              }
              loading={loading}
              setLoading={setLoading}
              handleSecondary={handleClose}
            />
          </div>
        </AlertBody>
      </Alert>
    </div>
  );
}

export default QuestionAddingBlock;
