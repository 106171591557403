import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Button } from "../common/button";
import { Divider } from "../common/divider";

function MovableCards({
  posts,
  onDragEnd,
  onConfirm,
  onEdit,
  onChange,
  onSearch,
  value,
  onDelete,
  type,
}) {
  function getCardsClasses(item) {
    return item.visibility === "no"
      ? "move cards movecards--invisible"
      : "movecards";
  }

  return (
    <div className="flex flex-col items-center min-h-screen h-auto">
      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-1">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="droppable-container"
              >
                {posts &&
                  posts.length > 0 && // Ensure posts is an array and has at least one item
                  posts.map((item, index) => {
                    if (!item) return null; // Skip if item is null or undefined
                    return (
                      <Draggable
                        key={item._id}
                        draggableId={"draggable_" + item._id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`${getCardsClasses(
                              item
                            )} bg-white rounded-2xl mt-7 mx-auto p-8 shadow w-full lg:w-1/1 max-w-screen-lg min-w-[28rem]`}
                          >
                            <div className="flex justify-between items-center mb-4">
                              <span></span>
                              <div className="flex space-x-4">
                                <Button
                                  outline
                                  onClick={() => onEdit(item, index)}
                                  className="text-blue-500 hover:text-blue-700 flex items-center"
                                >
                                  <span className="text-sm">Edit</span>
                                </Button>
                                <button
                                  onClick={() => onDelete(item, index)}
                                  className="text-red-500 hover:text-red-700 flex items-center"
                                >
                                  <TrashIcon className="w-6 h-6 mr-1" />
                                </button>
                              </div>
                            </div>
                            <p className="mb-2 whitespace-pre-wrap">
                              {item.question ? item.question : item.message}
                            </p>

                            <div className="flex justify-center"></div>
                            <Divider />
                            {type === "questionaddingBlock" && (
                              <div class="flex justify-between w-full pr-2 py-4 opacity-25 hover:opacity-70">
                                <div class="text-left text-sm">
                                  <span class="font-bold">Variable Name</span>:{" "}
                                  <span>{item.variableName}</span>
                                </div>
                                {/* <div class="text-center text-sm mx-2">
                                <span class="font-bold">Persist Keyword</span>:{" "}
                                <span>{item.tagConversation}</span>
                              </div> */}
                                <div class="text-right text-sm">
                                  <span class="font-bold">Condition</span>:{" "}
                                  <span>{item.condition}</span>
                                </div>
                              </div>
                            )}

                            {type === "keywordMatching" && (
                              <div class="flex justify-between w-full pr-2 py-4 opacity-25 hover:opacity-70">
                                <div class="text-left text-sm">
                                  <span class="font-bold">Keyword</span>:{" "}
                                  <span>{item.keyword}</span>
                                </div>
                                {/* <div class="text-center text-sm mx-2">
                                <span class="font-bold">Persist Keyword</span>:{" "}
                                <span>{item.tagConversation}</span>
                              </div> */}
                                <div class="text-right text-sm">
                                  <span class="font-bold">Action Type</span>:{" "}
                                  <span>{item.actionType}</span>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
}

export default MovableCards;
