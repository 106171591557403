import React, { useState } from "react";
import Search from "../../components/Search";
import * as knowledgeService from "../../services/knowledgebaseService";
import DeleteIcon from "../../images/delete.svg";
import {
  Alert,
  AlertActions,
  AlertBody,
  AlertDescription,
  AlertTitle,
} from "../../common/alert";
import { Button } from "../../common/button";

import { message } from "antd";

function ShowTextData({
  filteredKBData,
  setFilteredKBData,
  originalKBData,
  setFormState,
  setEditItem,
  projects,
  activeProject,
  reload,
  setReload
}) {
  let [searchQuery, setSearchQuery] = useState("");
  const [key, setKey] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleBackspaceKeyPress = (event) => {
    if (event.keyCode === 8) {
      const inputValue = searchQuery.slice(0, -1);

      setSearchQuery(inputValue);

      const filteredData = originalKBData.filter((paragraph) => {
        const lowerCasedValue = inputValue.toLowerCase().trim();
        return paragraph.value.data.toLowerCase().includes(lowerCasedValue);
      });

      setFilteredKBData(filteredData);
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);

    const filteredData = originalKBData.filter((paragraph) => {
      const lowerCasedValue = query.toLowerCase().trim();
      return paragraph.value.data.toLowerCase().includes(lowerCasedValue);
    });

    setFilteredKBData(filteredData);
  };

  const handleEdit = (item) => {
    setEditItem(item);
    setFormState("Edit Data");
  };

  const RevokeOk = async () => {

    setLoading(true)

    try {
      const response = await knowledgeService.deleteParagraph(
        projects[activeProject]._id,
        key
      );
      if (response.status === 200) {
        message.info("Deletion succesful");
        setIsOpen(false);
        setReload(!reload)
        setLoading(false)
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data.msg);
        setIsOpen(false);
        return;
      }
    }
  };

  const showDeleteModal = (item) => {
    setIsOpen(true);
    setKey(item.key);
  };

  return (
    <>
      <Search
        placeholder="Search for the exact phrases you have in your documents"
        onSearch={handleSearch}
        handleBackspaceKeyPress={handleBackspaceKeyPress}
        value={searchQuery}
      />
      <ul role="list" className="divide-y divide-gray-200">
        {filteredKBData &&
          filteredKBData.length > 0 &&
          filteredKBData
            .slice()
            .reverse()
            .map((item, index) => (
              <li
                key={index}
                className="flex flex-col justify-between m-8 rounded-2xl bg-white px-6 py-4 shadow max-w-4xl overflow-auto"
              >
                <div className="whitespace-pre-wrap mb-4">
                  {item.value.data}
                </div>
                <div className="flex justify-end space-x-4">
                  <Button outline onClick={() => handleEdit(item)}>
                    Edit
                  </Button>
                  <button
                    className="text-red-500 hover:text-red-700 transition duration-150 ease-in-out"
                    onClick={() => showDeleteModal(item)}
                  >
                    <img src={DeleteIcon} alt="Delete" className="w-5 h-5" />
                  </button>
                </div>
              </li>
            ))}
      </ul>
      <Alert open={isOpen} onClose={() => setIsOpen(false)} size="sm">
        <AlertTitle>Delete this info</AlertTitle>
        <AlertDescription>
          Once you confirm the deletion, this specific piece of information will
          be permanently removed from the entire system, and your AI agent will
          no longer have access to or be able to refer to this document.
        </AlertDescription>
        <AlertBody style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          ></div>
        </AlertBody>
        <AlertActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button onClick={() => RevokeOk()}>{loading?"Please wait....": "Yes, delete it"}</Button>
        </AlertActions>
      </Alert>
    </>
  );
}

export default ShowTextData;
