import { useState } from "react";
import { Input, Button, Tooltip, message } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { CopyToClipboard } from 'react-copy-to-clipboard';

function CopyInput({ value, title }) {
const [inputValue, setInputValue] = useState(value);

const handleCopy = () => {
message.success("Key copied");
}



return (
<Input.Group compact>
<label>{title}</label>
<Input
style={{ height: "33px", width: "calc(100% - 35px)" }}
value={inputValue}

/>
<Tooltip title="copy secret key">
<CopyToClipboard text={inputValue}>
<Button onClick={handleCopy} icon={<CopyOutlined />} />
</CopyToClipboard>
</Tooltip>
</Input.Group>
);
}

export default CopyInput;