import React from 'react';

function ButtonGroups({ buttons, activeLabel, handleButtonClick }) {
    return (
        <span className="isolate inline-flex rounded-md shadow-sm">
            {buttons.map((buttonLabel, index) => (
                <button
                    key={index}
                    type="button"
                    onClick={(e) => handleButtonClick(e)}  // Pass the event explicitly
                    value={buttonLabel}
                    className={`relative inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 ${
                        index === 0 ? 'rounded-l-md' : index === buttons.length - 1 ? 'rounded-r-md -ml-px' : '-ml-px'
                    } ${buttonLabel === activeLabel ? 'bg-gray-800 text-white' : 'bg-white'}`}
                >
                    {buttonLabel}
                </button>
            ))}
        </span>
    );
}

export default ButtonGroups;
