import http from "./httpService";
const apiEndpoint =  "/voiceembed";

export function updateSettings(projectId, req) {
    return http.put(apiEndpoint + "/" + projectId, req);
  }
  
  export function getSettings(projectId) {
  return http.get(apiEndpoint + "/" + projectId);
}


