import http from "./httpService";
// import { apiUrl } from "../config.json";

// const apiEndpoint = apiUrl + "/companies";
const apiEndpoint = "/company";

export function createCompany(obj) {

  return http.post(apiEndpoint, {
    firstName: obj.firstName,
    lastName: obj.lastName,
    name: obj.companyName,
    country: obj.country,
  });
  
}

export function findCompany(companyId) {
  return http.get(apiEndpoint + "/" + companyId )
}



export function allCompanyDetails(companyId) {
  return http.get(apiEndpoint + "/" + companyId )
}










export function createProject(companyId, req) {
  return http.post(apiEndpoint + "/"+ companyId, req);
}


export function createAccounts(companyId, req) {
  return http.post(apiEndpoint + "/playauth/" + companyId, req);
}





export function connectBusinessAuth(companyId, code) {
  return http.put(apiEndpoint + "/businessauth/" + companyId, {
    code: code,
  });
}



export function updateListing(listingId, req) {
  return http.put(apiEndpoint + "/" + listingId, req);
}

export function addTeammate(companyId, body) {
  return http.put(apiEndpoint + "/invite/" + companyId, {
    id: body.id,
  });
}

export function createSettings(companyId, arrayId, req) {
  return http.put(apiEndpoint + "/settings/" + companyId + "/"+ arrayId, req);
}


