import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "../../../components/Form";
import { message } from "antd";
import * as humanAgentService from "../../../services/humanAgentService";


function EditHumanAgent({ tabState, projects, activeProject, settings, item, setFormState, reload, setReload}) {

  const [inputField, setInputField] = useState({
    name: item?item.name:"",
    description: item?item.description:"",
    phoneNumber: item?item.phoneNumber:"",
    searchTerm: item?item.searchTerm:"",
    contactTemplate: "",
    contactMedium: item?item.contactMedium:"",
    language: "",
  });



  const [humanagents, setHumanAgents] = useState([]);
  const [forWhatsapp, setForWhatsapp] = useState(false);
  let [previewData, setPreviewData] = useState("");



//   const [searchItems, setSearchItems] = useState(
//     settings.searchItems && Array.isArray(settings.searchItems)
//       ? settings.searchItems
//       : settings.searchItems
//       ? [settings.searchItems]
//       : []
//   );

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const template = [
    {
      name: "name",
      label: "Name of the agent",
      type: "text",
    },
    {
      name: "contactMedium",
      label: "Channel",
      type: "select",
      options: [
        { id: 0, value: "", label: "No option selected" },
        { id: 2, value: "phone", label: "Phone call" },
      ],
    },
    {
      name: "description",
      label: "Describe when do you want to transfer the call",
      type: "textArea",
      style: { height: "150px" },
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      type: "text",
    },
    {
      name: "searchTerm",
      label: "Keyword",
      description: "Please enter a keyword for this agent. For example, if you have agents in different cities such as London, Paris, or Sydney, use the city name as the keyword. If the agent is associated with a specific department like Sales or Support, use the department name as the keyword.",
      type: "text"
    },
    ...(previewData.contactMedium === "whatsapp" ? [
      {
        name: "searchTerm",
        label: "Match keyword",
        type: "text",
      },
      {
        name: "contactTemplate",
        label: "Template Name (Copy from FB)",
        type: "text",
      },
      {
        name: "language",
        label: "Language (Copy from FB)",
        type: "select",
        options: [
          { id: 1, value: "af", label: "Afrikaans" },
          { id: 2, value: "sq", label: "Albanian" },
          { id: 3, value: "ar", label: "Arabic" },
          { id: 4, value: "az", label: "Azerbaijani" },
          { id: 5, value: "bn", label: "Bengali" },
          { id: 6, value: "bg", label: "Bulgarian" },
          { id: 7, value: "ca", label: "Catalan" },
          { id: 8, value: "zh_CN", label: "Chinese (CHN)" },
          { id: 9, value: "zh_HK", label: "Chinese (HKG)" },
          { id: 10, value: "zh_TW", label: "Chinese (TAI)" },
          { id: 11, value: "hr", label: "Croatian" },
          { id: 12, value: "cs", label: "Czech" },
          { id: 13, value: "da", label: "Danish" },
          { id: 14, value: "nl", label: "Dutch" },
          { id: 15, value: "en", label: "English" },
          { id: 16, value: "en_GB", label: "English (UK)" },
          { id: 17, value: "en_US", label: "English (US)" },
          { id: 18, value: "et", label: "Estonian" },
          { id: 19, value: "fil", label: "Filipino" },
          { id: 20, value: "fi", label: "Finnish" },
          { id: 21, value: "fr", label: "French" },
          { id: 22, value: "ka", label: "Georgian" },
          { id: 23, value: "de", label: "German" },
          { id: 24, value: "el", label: "Greek" },
          { id: 25, value: "gu", label: "Gujarati" },
          { id: 26, value: "ha", label: "Hausa" },
          { id: 27, value: "he", label: "Hebrew" },
          { id: 28, value: "hi", label: "Hindi" },
          { id: 29, value: "hu", label: "Hungarian" },
          { id: 30, value: "id", label: "Indonesian" },
          { id: 31, value: "ga", label: "Irish" },
          { id: 32, value: "it", label: "Italian" },
          { id: 33, value: "ja", label: "Japanese" },
          { id: 34, value: "kn", label: "Kannada" },
          { id: 35, value: "kk", label: "Kazakh" },
          { id: 36, value: "rw_RW", label: "Kinyarwanda" },
          { id: 37, value: "ko", label: "Korean" },
          { id: 38, value: "ky_KG", label: "Kyrgyz (Kyrgyzstan)" },
          { id: 39, value: "lo", label: "Lao" },
          { id: 40, value: "lv", label: "Latvian" },
          { id: 41, value: "lt", label: "Lithuanian" },
          { id: 42, value: "mk", label: "Macedonian" },
          { id: 43, value: "ms", label: "Malay" },
          { id: 44, value: "ml", label: "Malayalam" },
          { id: 45, value: "mr", label: "Marathi" },
          { id: 46, value: "nb", label: "Norwegian" },
          { id: 47, value: "fa", label: "Persian" },
          { id: 48, value: "pl", label: "Polish" },
          { id: 49, value: "pt_BR", label: "Portuguese (BR)" },
          { id: 50, value: "pt_PT", label: "Portuguese (POR)" },
          { id: 51, value: "pa", label: "Punjabi" },
          { id: 52, value: "ro", label: "Romanian" },
          { id: 53, value: "ru", label: "Russian" },
          { id: 54, value: "sr", label: "Serbian" },
          { id: 55, value: "sk", label: "Slovak" },
          { id: 56, value: "sl", label: "Slovenian" },
          { id: 57, value: "es", label: "Spanish" },
          { id: 58, value: "es_AR", label: "Spanish (ARG)" },
          { id: 59, value: "es_ES", label: "Spanish (SPA)" },
          { id: 60, value: "es_MX", label: "Spanish (MEX)" },
          { id: 61, value: "sw", label: "Swahili" },
          { id: 62, value: "sv", label: "Swedish" },
          { id: 63, value: "ta", label: "Tamil" },
          { id: 64, value: "te", label: "Telugu" },
          { id: 65, value: "th", label: "Thai" },
          { id: 66, value: "tr", label: "Turkish" },
          { id: 67, value: "uk", label: "Ukrainian" },
          { id: 68, value: "ur", label: "Urdu" },
          { id: 69, value: "uz", label: "Uzbek" },
          { id: 70, value: "vi", label: "Vietnamese" },
          { id: 71, value: "zu", label: "Zulu" },
        ],
      }
    ] : [])
  ];

  const buttonInfo = { label: "Edit Agent", style: "entire-width" };

  const schema = {
    name: Joi.string().required().max(50).label("Name"),
    description: Joi.string().required().max(300).label("Transfer condition"),
    searchTerm: Joi.string().allow(null, "").max(30).label("Match keyword"),
    phoneNumber: Joi.number().required().label("Phone number"),
    contactTemplate: Joi.string().allow(null, "").max(50).label("Template Name"),
    contactMedium: Joi.string().required().label("Medium"),
    language: Joi.string().allow(null, "").label("Language"),
  };

  const handleYes = async (data) => {
    try {
      const response = await humanAgentService.updateHumanAgent(
        projects[activeProject]._id,
        item._id,
        data
      );

      if (response.status === 200) {
        setLoading(false);
        setReload(!reload);
        message.success("Agent successfully added");
        setFormState("view")
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error("Request failed");
      setLoading(false);
    }
  };

  const getPreviewDataInParent = (dataDuplicate) => {
    if (!dataDuplicate) {
      setPreviewData("");
    }
    setPreviewData(dataDuplicate);
  };

  return (
    <div>
      <div className="divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-10">
        <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
          <span>Configure Human Agent Transfer</span>
        </div>
        <div className="px-64 pb-16">
          <Form
            key={JSON.stringify(inputField)}
            errorMessage={errorMessage}
            inputField={inputField}
            template={template}
            schema={schema}
            buttonInfo={buttonInfo}
            onSubmit={handleYes}
            loading={loading}
            setLoading={setLoading}
            getValue={getPreviewDataInParent}
          />
        </div>
      </div>
    </div>
  );
}

export default EditHumanAgent;
