import http from "./httpService";
const apiEndpoint = "/customActions";

export function addSMSTrigger(projectId, data) {
    return http.post(apiEndpoint + "/" + projectId, data);
}


export function getAllCustomActions(projectId) {
    return http.get(apiEndpoint + "/" + projectId);
}


export function deleteSMSAction(projectId, id) {
    return http.delete(apiEndpoint + "/deleteCustomAction/" + projectId + "/" + id);
  }


  export function updateCustomAction(projectId, id, req) {
    return http.put(apiEndpoint + "/updateCustomAction/" + projectId + "/" + id, req);
  }

//   export function updateTransferMessage(projectId, req) {
//     return http.put(apiEndpoint + "/updateTransferMessage/" + projectId, req);
//   }


  

  
