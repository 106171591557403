import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect,
} from "react";

class AudioQueueManager {
  constructor(audioElement, sendMarkLabel) {
    this.audioElement = audioElement;
    this.queue = [];
    this.isPlaying = false;
    this.currentSequence = 0;
    this.cleared = false;
    this.sendMarkLabel = sendMarkLabel; // Store the function to send mark label

    this.handleAudioEnded = this.handleAudioEnded.bind(this);
    this.sendMarkLabel = this.sendMarkLabel.bind(this);
  }

  enqueue(base64Data, sequence, mark) {
    const audioBlob = new Blob(
      [Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0))],
      { type: "audio/mpeg" }
    );
    const audioUrl = URL.createObjectURL(audioBlob);

    if (this.cleared) {
      this.currentSequence = sequence;
      this.cleared = false;
    }

    if (sequence === null) {
      this.playImmediately(audioUrl, mark);
    } else {
      this.queue.push({ url: audioUrl, sequence, mark });
      this.queue.sort((a, b) => a.sequence - b.sequence);
      this.checkAndPlay();
    }
  }

  playImmediately(audioUrl, mark) {
    if (this.isPlaying) {
      this.audioElement.pause();
      URL.revokeObjectURL(this.audioElement.src);
    }
    this.audioElement.src = audioUrl;
    this.audioElement.play()
      .then(() => {
        this.isPlaying = true;
        this.currentSequence++;
        this.audioElement.currentMark = mark; // Store the current mark
      })
      .catch((error) => console.error("Error playing audio:", error));
  }

  checkAndPlay() {
    if (!this.isPlaying && this.queue.length > 0) {
      const nextItem = this.queue[0];
      if (nextItem.sequence === this.currentSequence) {
        this.queue.shift();
        this.playNext(nextItem.url, nextItem.mark);
      }
    }
  }

  playNext(audioUrl, mark) {
    this.audioElement.src = audioUrl;
    this.audioElement.play()
      .then(() => {
        this.isPlaying = true;
        this.currentSequence++;
        this.audioElement.currentMark = mark; // Store the current mark
      })
      .catch((error) => console.error("Error playing audio:", error));
  }

  handleAudioEnded() {
    // console.log("handleAudioEnded triggered!")
    URL.revokeObjectURL(this.audioElement.src);
    this.isPlaying = false;
    // console.log(`Current audio mark is: ${this.audioElement.currentMark}`);
    if (this.audioElement.currentMark) {
      // console.log("Inside marklabel condition, sending marklabel")
      // console.log(`Logging sendMarkLabel function: ${this.sendMarkLabel}`);
      this.sendMarkLabel(this.audioElement.currentMark); // Send the mark label
    }
    this.checkAndPlay();
  }

  clearQueue() {
    this.queue.forEach((item) => URL.revokeObjectURL(item.url));
    this.queue = [];
    this.isPlaying = false;
    this.audioElement.pause();
    this.audioElement.src = "";
    this.cleared = true;
  }

  resetSequenceIndex() {
    this.currentSequence = 0;
  }
}


// Create and export WebcallContext directly
export const WebcallContext = createContext(null);

export const WebcallProvider = ({ children }) => {
  const [microphone, setMicrophone] = useState(null);
  const [socket, setSocket] = useState(null);
  const audioRef = useRef(new Audio());
  const [audioQueueManager, setAudioQueueManager] = useState(null);

  // We need to keep a ref for socket to make sure we always have the current socket available
  const socketRef = useRef(socket);
  useEffect(() => {
    socketRef.current = socket;
    // console.log("Setting socket reference")
  }, [socket]);

  const sendMarkLabel = (mark) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify({
        event: 'mark',
        mark: mark
      }));
    } else {
      console.error('WebSocket is not open or not defined.');
    }
  };

  useEffect(() => {
      if (audioRef.current && !audioQueueManager) {
        const manager = new AudioQueueManager(audioRef.current, sendMarkLabel);
        setAudioQueueManager(manager);
        audioRef.current.addEventListener('ended', manager.handleAudioEnded);
        // console.log("-------> UseEffect triggered within webcall context, audioQueueManager reset");
      }
  }, []);

  useEffect(() => {
      // console.log("Cleaning up socket and microphone")
      if (microphone) {
        microphone.stop();
        microphone.stream.getTracks().forEach((track) => track.stop());
        setMicrophone(null);
      }
      if (socketRef.current) {
        socketRef.current.close();
        setSocket(null);
      }
  }, []);

  const cleanupResources = () => {
    if (microphone) {
      microphone.stop();
      microphone.stream.getTracks().forEach((track) => track.stop());
      setMicrophone(null);
    }
    if (socketRef.current) {
      socketRef.current.close();
      setSocket(null);
    }
    if (audioRef.current) {
      if (!audioRef.current.paused) {
        audioRef.current.pause();
      }
      URL.revokeObjectURL(audioRef.current.src);
      audioRef.current.onended = null;
    }
    audioQueueManager?.resetSequenceIndex();
  };

  return (
    <WebcallContext.Provider value={{
      microphone,
      setMicrophone,
      socket: socketRef.current,
      setSocket,
      audioQueueManager,
      audioRef,
      cleanupResources,
    }}>
      {children}
    </WebcallContext.Provider>
  );
};

export const useWebcall = () => useContext(WebcallContext);