import React, { useEffect, useRef } from "react";
import { Button } from "../../common/button";
import { useWebcall } from "../../Context/WebcallContext";
import * as hark from "hark";


function Webcall({ projectID,settings }) {
  let {
    microphone,
    setMicrophone,
    socket,
    setSocket,
    audioQueueManager,
    audioRef,
    cleanupResources,
  } = useWebcall();

  let recorder = null;
  let speechEvents = null;
  let stream = null;

  const audioQueueManagerRef = useRef(audioQueueManager);
  useEffect(() => {
    //console.log("Setting audioQueueManagerRef within webcall");
    audioQueueManagerRef.current = audioQueueManager;
  }, [audioQueueManager]);

  const setupWebSocket = () => {
    let voiceURL = process.env.REACT_APP_VOICE_API;
    voiceURL = voiceURL.replace(/^https:\/\//, "");
    let socketURL = `wss://${voiceURL}/audio-webcall/${projectID}`;
    // socketURL = `wss://90fc-182-156-5-2.ngrok-free.app/audio-webcall/${projectID}`;

    socket = new WebSocket(socketURL);

    socket.onopen = () => {
      // console.log("Client: connected to server");
      startRecording(socket);
    };
    setSocket(socket);

    socket.onclose = () => {
      // console.log("Client: disconnected from server");

      cleanupResources();
      setTimeout(() => {
        setMicrophone(null);
        setSocket(null);
        stopRecording();
      }, 1000);
    }

    socket.onmessage = (event) => {
      const dataJSON = JSON.parse(event.data);
      if (dataJSON.event === "media") {
        // console.log(`Received audio message with sequence: ${dataJSON.media.sequence} and mark: ${dataJSON.media.mark}`)
        audioQueueManagerRef.current.enqueue(dataJSON.media.payload, dataJSON.media.sequence, dataJSON.media.mark);
      } else if (dataJSON.event === "clear") {
        // console.log("Clear message received from backend!!!");
        audioQueueManagerRef.current.clearQueue();
      } else if (dataJSON.event === "close") {
        // console.log("CLOSE message received from backend!!!");
        socket.close();
      }
    };
  };

  const startRecording = async (socket) => {
    stream = await navigator.mediaDevices.getUserMedia({
      audio: {
        sampleRate: 32000
      }
    })
    const options = {
      mimeType: 'audio/webm',
      //audioBitsPerSecond: 64000
    };
    const recorder = new MediaRecorder(stream, options);
    
    const harkOptions = {
      "threshold": settings && settings.voiceEmbed ? settings.voiceEmbed.interruptionThreshold : -40
  };  

    speechEvents = hark(stream, harkOptions);

    speechEvents.on("speaking", () => {
      console.log("User has started speaking");
      if (audioQueueManagerRef.current) {
        audioQueueManagerRef.current.clearQueue();
      }
      // streamCleared = true;
    });

    speechEvents.on("stopped_speaking", () => {
      console.log("User has stopped speaking");
      // streamCleared = false;
    });

    recorder.ondataavailable = async (event) => {
      if (event.data.size > 0 && socket.readyState === WebSocket.OPEN) {
        const buffer = await event.data.arrayBuffer();
        const base64String = btoa(String.fromCharCode(...new Uint8Array(buffer)));
        const message = JSON.stringify({ event: "media", media: { payload: base64String } });
        socket.send(message);
      }
    };

    recorder.start(1000);
    setMicrophone(recorder);
    /*
    stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    recorder = new MediaRecorder(stream, { mimeType: "audio/webm" });
    // let streamCleared = false;

    speechEvents = hark(stream, {});

    speechEvents.on("speaking", () => {
      // console.log("User has started speaking");
      if (audioQueueManagerRef.current) {
        audioQueueManagerRef.current.clearQueue();
      }
      // streamCleared = true;
    });

    speechEvents.on("stopped_speaking", () => {
      // console.log("User has stopped speaking");
      // streamCleared = false;
    });

    recorder.ondataavailable = async (event) => {
      if (event.data.size > 0 && socket.readyState === WebSocket.OPEN) {
        // socket.send(event.data);
        const buffer = await event.data.arrayBuffer();
        const base64String = btoa(String.fromCharCode(...new Uint8Array(buffer)));
        const message = JSON.stringify({ event: "media", media: { payload: base64String }});
        socket.send(message);
      }
    };

    recorder.start(1000);
    setMicrophone(recorder);
    */
  };

  const stopRecording = () => {
    // console.log("Stop recording is triggered")
    if (recorder) {
      recorder.stop();
    }
    if (speechEvents) {
      speechEvents.stop();
    }
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
    }
  };

  const handleRecordClick = () => {
    if (!microphone) {
      setupWebSocket();
    } else {
      microphone.stop();
      if (audioQueueManagerRef.current) {
        audioQueueManagerRef.current.clearQueue();
      }
      stopRecording();
      cleanupResources();
    }
  };

  return (
    <div>
      <Button
        key={microphone}
        id="record"
        onClick={handleRecordClick}
        color={microphone ? "rose" : "indigo"}
      >
        {microphone ? "Stop Web Call" : "Start Web Call"}
      </Button>
      <audio ref={audioRef} controls hidden />
    </div>
  );
}

export default Webcall;
