import React,{useState} from 'react';
import Joi from "joi-browser";
import Form from "../../components/Form";
import * as projectService from "../../services/projectService"
import { message } from "antd";

function S3({projects, activeProject, settings,settingReload,setSettingReload}) {

  const [formFieldforZohoCRM] = useState({
    awsAccessKeyID: settings?.awsCredentials?.awsAccessKeyID,
    awsSecretAccessKey:settings?.awsCredentials?.awsSecretAccessKey,
    awsRegion:settings?.awsCredentials?.awsRegion,
    awsBucket:settings?.awsCredentials?.awsBucket

  });

  const [loading, setLoading] = useState(false);

  const [errorMessageZohoCRM] = useState({});

  const templateZohoCRM = [
    {
      name: "awsAccessKeyID",
      label: "AWS Access key",
      type: "text",
      style: "",
    },
    {
        name: "awsSecretAccessKey",
        label: "AWS SecretKey",
        type: "password",
        style: "",
      },
      {
        name: "awsRegion",
        label: "AWS Region",
        type: "text",
        style: "",
      },
      
      {
        name: "awsBucket",
        label: "AWS Bucket",
        type: "text",
        style: "",
      },
  
  ];

  const buttonInfoZohoCRM = {
    label: "Connect S3",
    style: "entire-width",
  };

  const schemaZohoCRM = {
    awsAccessKeyID: Joi.string().max(5000).required().label("AWS Access key"),
    awsSecretAccessKey: Joi.string().max(5000).required().label("AWS Secret key"),
    awsRegion: Joi.required().label("AWS Region"),
    awsBucket:Joi.string().max(5000).required().label("AWS bucket"),
  };

  const handleS3Connection = async (data) => {
    setLoading(true);
    try {
      const response = await projectService.updateProject(
        projects[activeProject]._id,
        { awsCredentials: data }
      );
      if (response.status === 200) {
        message.success("Changes saved");
        setLoading(false);
        setSettingReload(!settingReload)
      }
    } catch (ex) {
      if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
        setLoading(false);  // Ensure setLoading is defined and used correctly
        message.error(ex.response.data.msg);
      } else {
        setLoading(false);
        console.error("An unexpected error occurred:", ex);
      }
    }
  };
  
  



    return (
        <div>
           <main className="lg:pl-22 bg-slate-50 h-screen">
      <div className="xl:pr-96 bg-slate-50">
        <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-slate-50">
          <div className="divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-10">
            <div className="px-4 py-5 sm:px-6">
              Configure your S3 configuration
            </div>
          
            <div className='px-64 pb-16'>
            <Form
              errorMessage={errorMessageZohoCRM}
              inputField={formFieldforZohoCRM}
              template={templateZohoCRM}
              schema={schemaZohoCRM}
              buttonInfo={buttonInfoZohoCRM}
              onSubmit={handleS3Connection}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
          </div>
        </div>
      </div>
    </main>
      </div>
    );
}

export default S3;