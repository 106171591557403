import React, { useState } from "react";
import { Subheading } from "../../../common/heading";
import { Button } from "../../../common/button";
import { Divider } from "../../../common/divider";
import { Switch } from "../../../common/switch";
import { message } from "antd";
import * as projectService from "../../../services/projectService";

function Default({
  projects,
  activeProject,
  settings,
  handleAddQuestionCustomize,
  handleAddKeywordTriggers,
  reload,
  setReload,
}) {


  const handleQuestionBlock = async () => {

    setWorkflowEnabled(!workflowEnabled)

    const data = {
      welcomeMessages: settings.RAGSettings.welcomeMessages[0],
      addQuestionsToChunks: settings.RAGSettings.addQuestionsToChunks,
      crmSearchEnabled: settings.RAGSettings.crmSearchEnabled,
      defaultCRM: settings.RAGSettings.defaultCRM,
      queryRewriteEnabled: settings.RAGSettings.queryRewriteEnabled,
      keywordMatchEnabled:settings.RAGSettings.keywordMatchEnabled,
      workflowEnabled: !workflowEnabled,
    };
    const response = await projectService.updateProject(
      projects[activeProject]._id,
      { RAGSettings: data }
    );
    if (response.status === 200) {
      message.success("Changes saved");
      setReload(!reload);
    }
  };


  const handleKeywordTrigger = async () => {

    setKeywordMatchEnabled(!keywordMatchEnabled)

    const data = {
      welcomeMessages: settings.RAGSettings.welcomeMessages[0],
      addQuestionsToChunks: settings.RAGSettings.addQuestionsToChunks,
      crmSearchEnabled: settings.RAGSettings.crmSearchEnabled,
      defaultCRM: settings.RAGSettings.defaultCRM,
      queryRewriteEnabled: settings.RAGSettings.queryRewriteEnabled,
      workflowEnabled: settings.RAGSettings.workflowEnabled,
      keywordMatchEnabled: !keywordMatchEnabled,
    };
    const response = await projectService.updateProject(
      projects[activeProject]._id,
      { RAGSettings: data }
    );
    if (response.status === 200) {
      message.success("Changes saved");
      setReload(!reload);
    }
  };


  const items = [
    {
      title: "Conditional Questions Block",
      descriptionPart1:
        "The Conditional question block gives you powerful customization abilities to ask questions conditionally to users.",
      descriptionPart2:
        "Please note, when you enable this feature, you need to strictly remove all the questions from the base prompt.",
      onClick: handleQuestionBlock, 
      onClickButton: handleAddQuestionCustomize, 
      
    },
    {
      title: "Keyword triggers",
      descriptionPart1:
        "Keyword triggers let you attach conversations with specific tags based on keywords or fire an event based on a specific keyword.",
      onClick: handleKeywordTrigger, 
      onClickButton:handleAddKeywordTriggers,
    }
  ];

  const [workflowEnabled, setWorkflowEnabled] = useState(
    settings?.RAGSettings?.workflowEnabled
  );

  const [keywordMatchEnabled, setKeywordMatchEnabled] = useState(
    settings?.RAGSettings?.keywordMatchEnabled
  );


  return (
    <ul role="list" className="space-y-3 mx-auto cursor-pointer">
      {items.map((item, index) => (
        <li
          key={index}
          className="overflow-hidden rounded-md bg-white px-6 py-4 shadow my-3"
        >
          <div className="flex justify-between items-center">
            <Subheading>{item.title}</Subheading>
            <div className="flex items-center space-x-2">
              <p>Enable</p>
              <Switch
                color="emerald"
                id={`checkbox-${index}`}
                checked={index === 0 ? workflowEnabled : keywordMatchEnabled}
                onClick={item.onClick}
              />
            </div>
          </div>
          <Divider className="my-4" />
          <p className="text-sm">{item.descriptionPart1}</p>
          <p className="text-sm">{item.descriptionPart2}</p>
          <div className="flex space-x-2">
            <Button
              className="mt-4"
              onClick={item.onClickButton} 
              outline
            >
              Add
            </Button>
          </div>
        </li>
      ))}
    </ul>
  );
}

export default Default;
