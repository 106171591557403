import React, { useState } from "react";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { Button } from "../common/button";

const ExpandableButton = ({handleClick}) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className="relative cursor-pointer flex items-center justify-center transition-all duration-300 mr-3"
    >
      <div
        className={`rounded-full flex items-center justify-center  transition-all duration-300 ${
          hover ? "w-auto px-4 py-2" : "w-10 h-10"
        }`}
      >
        {hover ? (
          <Button onClick={handleClick} className="flex items-center justify-center text-black">
            <PlusCircleIcon className="h-5 w-5 mr-2" />
            Create New Workspace
          </Button>
        ) : (
          <PlusCircleIcon onClick={handleClick} className="h-5 w-5 text-black" />
        )}
      </div>
    </div>
  );
};

export default ExpandableButton;