import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import auth from '../services/authService';

function ProtectedRoute({ element: Component }) {
  const location = useLocation();
  const user = auth.getCurrentUser();
  
  return user ? <Component user={user} /> : <Navigate to="/login" replace state={{ from: location }} />;
}


export default ProtectedRoute;
