import http from "./httpService";
const apiEndpoint = "/conversation";

export function allConversation(projectId) {
    return http.get(apiEndpoint + "/project/" + projectId )
  }

  export function deleteConversation(projectId, conversationId) {
    return http.delete(apiEndpoint + "/" + "deleteConversation" + "/" + projectId + "/" + conversationId )
  }

  



  