import http from "./httpService";
const apiEndpoint = "/apiKey";


export function generateKey(projectId) {
    return http.post(apiEndpoint + "/" + projectId);
}

export function allKeys(projectId) {
    return http.get(apiEndpoint + "/" + projectId);
}

export function deleteKey(keyId) {
    return http.delete(apiEndpoint + "/" + keyId);
}








