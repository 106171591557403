import React, { useState, useEffect } from "react";
import { Button } from "../../common/button";
import zohoCRM from "../../images/zohoCRM.svg";
import GHL from "../../images/GHL.png";
import Joi from "joi-browser";
import Form from "../../components/Form";
import * as thirdPartyServices from "../../services/thirdpartyService";
import { message } from "antd";

function TwilioComponent({ activeProject, projects }) {
  const [formFieldforTwilio, setFormFieldForTwilio] = useState({
    twilioAccountSID: "",
    twilioAuthToken: "",
    twilioPhoneNumber: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (projects[activeProject]._id) {
          const response = await thirdPartyServices.getthirdpartyData(
            projects[activeProject]._id
          );
          const { twilioAccountSID, twilioAuthToken, twilioPhoneNumber } = response.data.telephonySettings.twilioSettings;
          const twilioSettings = { twilioAccountSID, twilioAuthToken, twilioPhoneNumber };
          setFormFieldForTwilio(twilioSettings);
          
        }
      } catch (error) {}
    };
    fetchData();
  }, [projects, activeProject]);



  const [loading, setLoading] = useState(false);

  const [errorMessageZohoCRM] = useState({});

  const templateZohoCRM = [
    {
      name: "twilioAccountSID",
      label: "Your Twilio Account SID",
      type: "text",
      style: "",
    },
    {
      name: "twilioAuthToken",
      label: "Your Twilio Auth Token",
      type: "password",
      style: "",
    },
    {
      name: "twilioPhoneNumber",
      label: "Your Twilio Phone number (prefix country code)",
      type: "text",
      style: "",
    },
  ];

  const buttonInfoZohoCRM = {
    label: "Connect Twilio",
    style: "entire-width",
  };

  const schemaZohoCRM = {
    twilioAccountSID: Joi.string()
      .max(5000)
      .required()
      .label("Your Twilio Account SID"),
    twilioAuthToken: Joi.string()
      .max(5000)
      .required()
      .label("Your Twilio Auth SID"),
    twilioPhoneNumber: Joi.required().label("Your Twilio Phone number"),
  };

  const handleTwilioConnection = async (data) => {
    let telephonySettings = {
      telephonySettings: {
        telephonyProvider: "twilio",
        twilioSettings: {
          twilioAccountSID: data.twilioAccountSID,
          twilioAuthToken: data.twilioAuthToken,
          twilioPhoneNumber: data.twilioPhoneNumber,
        },
      },
    };

    try {
      const response = await thirdPartyServices.thirdpartyUpdate(
        telephonySettings,
        projects[activeProject]._id
      );
      if (response.status === 200) {
        message.success("Twilio connected");
        setLoading(false);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false); // Ensure setLoading is defined and used correctly
        message.error(ex.response.data.msg);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <div className="flex flex-col items-center bg-white mt-10 ">
        <div className="w-full max-w-md px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-white shadow rounded-2xl">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Twilio Account
          </h3>
          <div className="mt-2 text-sm text-gray-500">
            <p>
              Bring your own Twilio account to build your custom voice project.
            </p>
          </div>

          <div>
            <Form
              key={JSON.stringify(formFieldforTwilio)}
              errorMessage={errorMessageZohoCRM}
              inputField={formFieldforTwilio}
              template={templateZohoCRM}
              schema={schemaZohoCRM}
              buttonInfo={buttonInfoZohoCRM}
              onSubmit={handleTwilioConnection}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TwilioComponent;
