import React, { useState, useEffect } from "react";
import { message } from "antd";
import Form from "../../../components/Form";
import Joi from "joi-browser";
import * as projectService from "../../../services/projectService";
import Drag from "../../../images/drag.svg";
import { Button } from "../../../common/button";
import MovableCards from "../../../components/MovableCards";

import {
  Alert,
  AlertActions,
  AlertBody,
  AlertDescription,
  AlertTitle,
} from "../../../common/alert";
import { Switch } from "../../../common/switch";

function KeywordMatching({ projects, activeProject, setMainMenuState }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [formState, setFormState] = useState("");
  const [deleteItem, setDeleteItem] = useState("");
  const [indexForEdit, setIndexForEdit] = useState("add");
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);

  const [toggleValueforAdvancedOptions, setToggleValueforAdvancedOptions] =
    useState(false);
  let [isOpen, setIsOpen] = useState(false);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  let [posts, setPosts] = useState([]);

  const [reload, setReload] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await projectService.getPosts(
        projects[activeProject]._id
      );
      setPosts(data.keywordMatchData);
    };
    fetchData();
  }, [reload]);

  useEffect(() => {
    if (searchQuery) {
      let postsReceived = posts.filter((m) =>
        m.question.toLowerCase().includes(searchQuery.toLowerCase())
      );
      let postsPending = posts.filter(
        (m) => !m.question.toLowerCase().includes(searchQuery.toLowerCase())
      );

      postsPending.map((item) => (item.visibility = "no"));
      posts = [...postsReceived, ...postsPending];
      setPosts(posts);
    }
  }, [searchQuery]);

  const onDragEnd = async (props) => {
    const srcI = props.source.index;


    if (!props.destination) {
      message.error("Please drop the item only into the permitted area");
      return;
    }

    const desI = props.destination.index;

    const newPosts = Array.from(posts);
    const [movedItem] = newPosts.splice(srcI, 1);
    newPosts.splice(desI, 0, movedItem);
    setPosts(newPosts);

    try {
      const response = await projectService.updateProject(
        projects[activeProject]._id,
        {
          keywordMatchData
: newPosts,
        }
      );
      if (response.status === 200) {
        message.success("Successfully rearranged");
        setReload(!reload);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data);
        return;
      }
    }
  };

  const handleAdd = () => {
    setIsOpen(false);
    setFormState("add");
    setInputField({
      keyword: "",
      tagConversation: "",
      actionType: "",
      message: "",
    });
    //   setFormKey((prevKey) => prevKey + 1);
  };

 

  let [inputField, setInputField] = useState({
    keyword: "",
    tagConversation: "",
    actionType: "",
    message: "",
  });

  useEffect(() => {}, [inputField]);

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const template = [
    {
      name: "keyword",
      label: "Keyword[s] to search for",
      type: "text",
    },
    {
      name: "tagConversation",
      label: "Detection Type",
      type: "select",
      options: [
        { id: 0, value: "", label: "No selection" },
        {
          id: 1,
          value: true,
          label: "Detect this keyword and persist the information.",
        },
        {
          id: 2,
          value: false,
          label: "Detect if present only in the last message.",
        },
      ],
    },
    {
      name: "actionType",
      label: "Type of action",
      type: "select",
      options: [
        { id: 0, value: "", label: "No selection" },
        { id: 1, value: "send_message", label: "Tweak AI actions going forward" },
      ],
    },
    {
      name: "message",
      label: "Instructions to AI",
      type: "textArea",
      style: { height: "200px" },
    },
  ];

  const buttonInfo = {
    label: formState === "edit" ? "Save changes" : " Add a question",
    style: "",
  };

  const schema = {
    keyword: Joi.string().required().max(100).label("Keyword"),
    tagConversation: Joi.boolean().invalid("").label("Detection Type"),
    actionType: Joi.string()
      .invalid("")
      .required()
      .max(30)
      .label("Type of action"),
    message: Joi.string().label("Message"),
  };

  const handleAddVisibility = () => {
    setIsOpen(true);
    setFormState("add");
    setInputField({
      keyword: "",
      tagConversation: "",
      actionType: "",
      message: "",
    });
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleToggleValue = () => {
    setToggleValueforAdvancedOptions(!toggleValueforAdvancedOptions);
  };

  const handleQuestion = async (post) => {
    let newPosts;

    if (posts.length === 0) {
      newPosts = [post]; // Wrap post in an array when posts is empty
    } else {
      newPosts = [...posts, post]; // Append post to posts
    }

    try {
      const response = await projectService.updateProject(
        projects[activeProject]._id,
        { keywordMatchData: newPosts }
      );
      // const response = await customerService.addCustomer(projectId, data);

      if (response.status === 200) {
        setIsOpen(false);
        setLoading(false);
        message.success("New question added successfully");
        setReload(!reload);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false);
        message.error(ex.response.data.msg);
      }
    }
  };

  const handleEdit = async (item, index) => {
    setIsOpen(true);

    setFormState("edit");
    setIndexForEdit(index);
    // console.log("edititem", posts[index])
    setInputField({
      keyword: posts[index].keyword,
      tagConversation: posts[index].tagConversation,
      actionType: posts[index].actionType,
      message: posts[index].message,
    });

    setToggleValueforAdvancedOptions(posts[index].enableAdvancedOptions);

    //   setFormKey(prevKey => prevKey + 1);
  };

  const handleEditQuestion = async (post) => {
    try {
      post.enableAdvancedOptions = toggleValueforAdvancedOptions;
      let newPosts = [...posts];
      newPosts[indexForEdit] = post;

      const response = await projectService.updateProject(
        projects[activeProject]._id,
        { keywordMatchData
: newPosts }
      );

      if (response.status === 200) {
        setIsOpen(false);
        setLoading(false);
        message.success("Edit successful");
        setReload(!reload);
      }
    } catch (ex) {
      setLoading(false);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data.msg);
      } else {
        message.error("An unexpected error occurred");
      }
    }
  };

  const handleDelete = async () => {
    try {
      const updatedPosts = [...posts];
      updatedPosts.splice(deleteItem, 1);
      const response = await projectService.updateProject(
        projects[activeProject]._id,
        { keywordMatchData
: updatedPosts }
      );
      if (response.status === 200) {
        message.success("Post deleted");
        setReload(!reload);
        setIsDeleteConfirmation(false);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data);
        return;
      }
    }
  };

  const onDelete = (keyId, index) => {
    setIsDeleteConfirmation(true);
    setIsDeleteConfirmation(true);
    setDeleteItem(index);
  };

  const handleBack = () => {
    setMainMenuState("Default");
  };

  return (
    <div className="relative divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-5">
      <div className="px-4 py-5 sm:px-6 flex items-center justify-between">
        <div className="flex items-center">
          <img src={Drag} alt="Drag Icon" className="mr-2 w-5 h-5 mr-10" />
          <span>
            You can add keyword triggers and further drag and   drop position of
            added  triggers to <br/> customize workflows
          </span>
        </div>
        <Button outline onClick={handleBack}>
          Go back
        </Button>
        <Button outline onClick={handleAddVisibility}>
          Add a trigger
        </Button>
      </div>
      <div className="overflow-hidden rounded-md bg-slate-50 px-6 py-4 shadow">
        <MovableCards
          type="keywordMatching"
          value={searchQuery}
          posts={posts}
          onSearch={handleSearch}
          onDragEnd={onDragEnd}
          // onConfirm={showDeleteModal}
          onEdit={handleEdit}
          // onChange={handleState}
          onDelete={onDelete}
        />
      </div>

      <Alert open={isDeleteConfirmation} onClose={setIsDeleteConfirmation}>
        <AlertTitle>Confirm Deletion</AlertTitle>
        <AlertDescription>
          Please confirm, do you want to delete this.
        </AlertDescription>
        <AlertActions>
          <Button plain onClick={() => setIsDeleteConfirmation(false)}>
            Cancel
          </Button>
          <Button onClick={() => handleDelete(deleteItem)}>Delete</Button>
        </AlertActions>
      </Alert>

      <Alert open={isOpen} onClose={() => setIsOpen(false)} size="sm">
        <AlertTitle className="flex justify-between items-center">
          <span>Create</span>
        </AlertTitle>

        <AlertBody style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Form
              key={JSON.stringify(inputField)}
              errorMessage={errorMessage}
              inputField={inputField}
              template={template}
              schema={schema}
              buttonInfo={{
                style: "",
                buttons: "2",
                primaryLabel: "Save changes",
                secondaryLabel: "Close",
              }}
              onSubmit={
                formState === "edit" ? handleEditQuestion : handleQuestion
              }
              loading={loading}
              setLoading={setLoading}
              handleSecondary={handleClose}
            />
          </div>
        </AlertBody>
      </Alert>
    </div>
  );
}

export default KeywordMatching;
