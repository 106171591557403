import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../common/table";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../../common/dropdown";
import {
  Alert,
  AlertActions,
  AlertBody,
  AlertDescription,
  AlertTitle,
} from "../../common/alert";
import { TbCapture } from "react-icons/tb";
import Joi from "joi-browser";

import { Button } from "../../common/button";
import * as webhookstatusService from "../../services/webhookstatusService";
import Form from "../../components/Form";
import * as webhookService from "../../services/webhookService";
import { message } from "antd";
import { Badge } from "../../common/badge";
import ReactJsonPretty from 'react-json-pretty';

function Webhooks({ projects, activeProject }) {
  const [webhook, SetWebhooks] = useState([]);
  const [title, setTitle] = useState("");
  const [data, setData] = useState({});
  const [modalStatus, setModalStatus] = useState(false);
  const [item, setItem] = useState({});
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let [isOpen, setIsOpen] = useState(false);
  let[isRetryOpen, setIsRetryOpen] = useState(false);
  let[isDataExtractedOpen, setIsDataExtractedOpen] = useState(false)

  const getTime = (data) => {
    if (data === 0) return "Never";

    const epochTime = data;
    const date = new Date(epochTime);
    const dateString = date.toLocaleString(); // or use toUTCString() or toDateString() as needed
    return dateString;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (
        Array.isArray(projects) &&
        activeProject >= 0 &&
        projects.length > activeProject
      ) {
        const project = projects[activeProject];

        if (project && project._id) {
          const { data } = await webhookstatusService.getAllWebhooks(
            project._id
          );
          const reversedData = data.response.reverse();
          SetWebhooks(reversedData);
        }
      }
    };

    fetchData();
  }, [reload, projects, activeProject]);

  const [inputField] = useState({
    eventName: "",
    endpointUrl: "",
    authKey: "",
    authRequired: false,
  });

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const template = [
    {
      name: "eventName",
      label: "Event Type",
      type: "select",
      options: [
        { id: 0, value: "", label: "No selection" },
        {
          id: 1,
          value: "captureEvent",
          label: "Capture details from conversation",
        },
      ],
    },

    {
      name: "endpointUrl",
      label: "Endpoint URL",
      type: "text",
    },

    {
      name: "authRequired",
      label: "Auth required",
      description: "Only enable it if Auth is required",
      type: "toggle",
    },

    {
      name: "authKey",
      label: "Auth Key",
      type: "text",
    },
  ];

  const buttonInfo = { label: "Submit", style: "" };

  const schema = {
    eventName: Joi.string().required().label("event name"),
    endpointUrl: Joi.string().required().label("endpointUrl"),
    authRequired: Joi.boolean().label("Auth required"),
    authKey: Joi.string().allow(null, "").label("authKey"),
  };

  const handleCreate = async (data) => {
    setLoading(true);
    try {
      const response = await webhookService.subscribetoWebhook(
        projects[activeProject]._id,
        data.eventName,
        data.endpointUrl,
        data.authRequired,
        data.authKey
      );
      if (response.status === 201 || response.status === 200) {
        message.success("Success");
        setLoading(false);
        setIsOpen(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsOpen(false);
      message.error("request failed");
      setLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSuccess =()=>{
    message.info("This request was successfully posted. No further action can be performed")
  }


  const handleRetry = async(item)=>{
    setIsLoading(true)
    try {
      const response = await webhookstatusService.retyWebhooks(item._id, projects[activeProject._id]);
    
      if (response.status === 200) {
        setIsLoading(false)
        setReload(!reload);
        message.success("Success");
        setIsRetryOpen(false)
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsLoading(false)
      setIsRetryOpen(false)
    }

  }

  const handleJSONModal =(item)=>{
  setIsDataExtractedOpen(true)
  setData(item.extracted_data)
  }



  return (
    <div>
      <main className="lg:pl-22 bg-slate-50 h-screen">
        <div className="xl:pr-96 bg-slate-50">
          <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-slate-50">
            <div className="divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-10">
              <div className="flex justify-between items-center px-4 py-5 sm:px-6">
                <div>Create a webhook</div>
                <Button onClick={() => setIsOpen(true)} type="button">
                  Create
                </Button>
              </div>
              <Alert open={isOpen} onClose={() => setIsOpen(false)} size="sm">
                <AlertTitle>Create A Webhook</AlertTitle>
                {/* <AlertDescription>
                    For security purposes, it's advised that you save this secret
                    key in a secure and accessible location. Once saved, please
                    note that it won't be visible again through your Superdash
                    account. If the secret key is lost, generating a new one will
                    be required.
                  </AlertDescription> */}
                <AlertBody style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <Form
                      errorMessage={errorMessage}
                      inputField={inputField}
                      template={template}
                      schema={schema}
                      buttonInfo={{
                        style: "",
                        buttons: "2",
                        primaryLabel: "Create A Webhook",
                        secondaryLabel: "Close",
                      }}
                      onSubmit={handleCreate}
                      loading={loading}
                      setLoading={setLoading}
                      handleSecondary={handleClose}
                    />
                  </div>
                </AlertBody>
              </Alert>
              <div className="bg-white ">
                {webhook.length > 0 && (
                  <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)] p-5">
                    <TableHead>
                      <TableRow>
                        <TableHeader>Webhook ID</TableHeader>
                        <TableHeader>Data</TableHeader>
                        <TableHeader>Status</TableHeader>
                        <TableHeader>Timestamp</TableHeader>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {webhook.length > 0 &&
                        webhook.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell className="font-medium">
                              {item.webhookId}
                            </TableCell>
                            <TableCell>
                              {" "}
                              <TbCapture className="cursor-pointer h-5" onClick={() => handleJSONModal(item)} />
                            </TableCell>
                            <TableCell>
                              {item.status === "failed" ? (
                                <Button onClick={() => setIsRetryOpen(true)} color="rose">
                                  {item.status}
                                </Button>
                              ) : item.status === "finished" ? (
                                <Button onClick={handleSuccess} color="lime">
                                {item.status}
                              </Button>
                              ) : (
                                <Button outline color="light">
                                {item.status}
                              </Button>
                              )}
                            </TableCell>

                            <TableCell>
                              <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                                {getTime(item.timestamp)}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                )}
              </div>
            </div>
          </div>
        </div>
        <Alert open={isRetryOpen} onClose={setIsRetryOpen}>
        <AlertTitle>Retry webhook</AlertTitle>
        <AlertDescription>
        Please confirm, do you want to retry the webhook now?
        </AlertDescription>
        <AlertActions>
          <Button plain onClick={() => setIsRetryOpen(false)}>
            Cancel
          </Button>
          <Button onClick={() => handleRetry(item)}>Retry</Button>
        </AlertActions>
      </Alert>

      <Alert className="w-full overflow-auto p-4 " open={isDataExtractedOpen} onClose={setIsDataExtractedOpen}>
        <AlertTitle>Extracted Data</AlertTitle>
        <AlertBody>
        <ReactJsonPretty json={data} />
        </AlertBody>
        <AlertActions>
          <Button plain onClick={() => setIsDataExtractedOpen(false)} >
            Cancel
          </Button>
        </AlertActions>
      </Alert>

      </main>

      <aside className="fixed inset-y-0 right-0 hidden w-96 overflow-y-auto border-gray-200 px-4 py-6 sm:px-6 lg:px-8 xl:block bg-slate-50 mt-20 mx-1 rounded-2xl shadow-md bg-white">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 p-10">
          <p>
            Here you can find other information to successfully configure your
            API connections.
          </p>
          <br />
          <div className="flex items-center space-x-2 cursor-pointer">
            {" "}
            <h3>ProjectId</h3>
          </div>
          <div></div>
          <p className="text-gray-400 my-1"></p>{" "}
        </div>
      </aside>
    </div>
  );
}

export default Webhooks;
