import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "../../../components/Form";
import { message } from "antd";
import * as humanAgentService from "../../../services/humanAgentService";
import * as projectService from "../../../services/projectService";
import { Switch } from "../../../common/switch";
import ButtonGroups from "../../../common/buttonGroups";
// import Configuration from "./Configuration";
import NewEndCondition from "./NewEndCondition";
import SettingsCallEnd from "./SettingsCallEnd";

function ParentCallEnd({ projects, activeProject, settings }) {
  const buttonLabels = ["Add new", "Settings"];

  const [tabState, setTabState] = useState("Add new");

  const handleButtonClick = (event) => {
    const buttonValue = event.target.value;
    setTabState(buttonValue);
  };

  return (
    <div>
      <main className="lg:pl-22 bg-slate-50 h-screen">
        <div className="xl:pr-96 bg-slate-50">
          <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-slate-50">
            <div className="flex justify-end mb-5">
              <ButtonGroups
                handleButtonClick={handleButtonClick}
                buttons={buttonLabels}
                activeLabel={tabState}
              />
            </div>
            {tabState === "Add new" && (
              <NewEndCondition
                projects={projects}
                activeProject={activeProject}
                settings={settings}
                setTabState={setTabState}
              />
            )}
            {tabState === "Settings" && (
              <SettingsCallEnd
                projects={projects}
                activeProject={activeProject}
                settings={settings}
                setTabState={setTabState}
              />
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default ParentCallEnd;
