import React, { useState, useEffect } from "react";
import { Checkbox, CheckboxField, CheckboxGroup } from "../../common/checkbox";
import { Description, Label } from "../../common//fieldset";
import { Button } from "../../common/button";
import * as projectService from "../../services/projectService";
import { message } from "antd";

function ZohoCRM({ projects, activeProject, settings, reload, setReload }) {
  const [crmSearchEnabled, setCRMSearchEnabled] = useState(
    settings?.RAGSettings?.crmSearchEnabled && settings?.RAGSettings?.defaultCRM === "ZohoCRM"
  );
  


  // useEffect(() => {
  //   if (settings) {
  //     setInputField((prevState) => {
  //       const newState = {
  //         welcomeMessages: settings.RAGSettings.welcomeMessages,
  //       };

  //       return newState;
  //     });
  //     setReload(!reload);
  //   }
  // }, [settings, projects, activeProject]);

  const handleSave = async () => {
    const data = {
      welcomeMessages: settings.RAGSettings.welcomeMessages[0],
      addQuestionsToChunks: settings.RAGSettings.addQuestionsToChunks,
      crmSearchEnabled: crmSearchEnabled,
      defaultCRM:"ZohoCRM",
      queryRewriteEnabled: settings.RAGSettings.queryRewriteEnabled,
      workflowEnabled: settings.RAGSettings.workflowEnabled
    };
    const response = await projectService.updateProject(
      projects[activeProject]._id,
      { RAGSettings: data }
    );
    if (response.status === 200) {
      message.success("Changes saved");
      setReload(!reload);
    }
  };
  

  const handleZohoContactsSearch =()=>{
    setCRMSearchEnabled(!crmSearchEnabled)
    message.info("Please click save to persist this configuration")
  }



  return (
    <main className="lg:pl-22 bg-slate-50 h-screen">
      <div className="xl:pr-96 bg-slate-50">
        <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-slate-50">
          <div className="divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-10">
            <div className="px-4 py-5 sm:px-6">
              Configure your Zoho configuration
            </div>
            <div className="p-5">
              <CheckboxGroup>
                <CheckboxField>
                  <Checkbox name="zoho_crm_search"  onChange={handleZohoContactsSearch} value={crmSearchEnabled} checked={crmSearchEnabled} />
                  <Label>Zoho contacts search</Label>
                  <Description>
                    Turn on this feature to automatically use the first name of
                    individuals from your Zoho CRM contacts on an inbound or
                    outbound call. Currently available only for the voice app
                  </Description>
                </CheckboxField>
              </CheckboxGroup>
            </div>
            <div className="px-4 py-4 sm:px-6 flex justify-end">
              <Button onClick={handleSave}>Save</Button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ZohoCRM;
