import React, { useEffect, useState } from "react";
import ButtonGroups from "../common/buttonGroups";
import * as KBService from "../services/knowledgebaseService";
import Search from "../components/Search";
import TextData from "./HomeKBComponent/ShowTextData";
import LinksnUploads from "./HomeKBComponent/LinksnUploads";
import AddKnowledgebase from "./HomeKBComponent/AddKnowledgebase";
import ShowTextData from "./HomeKBComponent/ShowTextData";
import EditTextData from "./HomeKBComponent/EditTextData";
import { HomeIcon } from '@heroicons/react/20/solid'

function HomeKnowledgebase({ projects, activeProject, projectId }) {
  const buttonLabels = [
    "Add Data",
    "Existing Text Data",
    "Existing Links & Uploads",
  ];

  const [activeState, setActiveState] = useState("default");

  const [KB, setKB] = useState([]);
  const [formState, setFormState] = useState("Add Data");
  const [editItem, setEditItem] = useState({});
  const [originalKBData, setOriginalKBData] = useState([]);
  const [filteredKBData, setFilteredKBData] = useState([]);
  const [key, setKey] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [reload, setReload] = useState(false);

  // const history = useHistory();

  useEffect(() => {

    if (KB && KB.length > 1 && KB.paragraphs) {
      setOriginalKBData(KB.paragraphs);
      setFilteredKBData(KB.paragraphs);
    }
  }, [KB,reload]);

  useEffect(() => {
    const fetchData = async () => {
      if (projects[activeProject]) {
        const value = await KBService.getSpecificKB(
          projects[activeProject]._id
        );
        setKB(value.data);
        setOriginalKBData(
          value.data && value.data.paragraphs ? value.data.paragraphs : []
        );
        setFilteredKBData(
          value.data && value.data.paragraphs ? value.data.paragraphs : []
        );
        // const convo = await conversationService.allConversation(
        //   projects[activeProject]._id
        // );

        // convo.data.conversations.reverse();
        // setConversation(convo.data);

        // const response = await projectService.getSettings(
        //   projects[activeProject]._id
        // );
        // setSettings(response.data);
      }
    };
    fetchData();
  }, [activeProject, projects, reload]);

  const handleButtonClick = (event) => {
    const buttonValue = event.target.value;
    setFormState(buttonValue);
  };

  const handleHome =()=>{
    setActiveState("default")
  }

  return (
    <div className="flex h-screen bg-slate-50">
      <main className="flex-grow flex flex-col overflow-auto">
        {" "}
        {/* Added overflow-auto here */}
        <div className="flex justify-between items-start w-full px-4 py-10 sm:px-6 lg:px-8 lg:py-6 ">
        <div className="flex items-center">
    <span className="text-lg font-medium mr-4">Current Data</span>
    {formState==="Add Data" && <nav  onClick={handleHome} className="flex" aria-label="Breadcrumb">
        <ol role="list" className="flex space-x-4 rounded-md bg-white px-6 shadow">
            <li className="flex">
                <div className="flex items-center">
                    <a  className="text-gray-400 hover:text-gray-500 p-1 cursor-pointer">
                        <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                        <span className="sr-only">Go Home</span>
                    </a>
                </div>
            </li>
        </ol>
    </nav>}
</div>

          <div className="flex-shrink-0">
            <ButtonGroups
              handleButtonClick={handleButtonClick}
              buttons={buttonLabels}
              activeLabel={formState}
            />
          </div>
        </div>
        <div className="m-10">
          {formState === "Existing Text Data" && (
            <ShowTextData
              filteredKBData={filteredKBData}
              setFilteredKBData={setFilteredKBData}
              originalKBData={originalKBData}
              setFormState={setFormState}
              setEditItem={setEditItem}
              projects={projects}
              activeProject={activeProject}
              reload={reload}
              setReload={setReload}
            />
          )}
          {formState === "Existing Links & Uploads" && (
            <LinksnUploads
              filteredKBData={filteredKBData}
              setFilteredKBData={setFilteredKBData}
              originalKBData={originalKBData}
              KB={KB}
              reload={reload}
              setReload={setReload}
              projects={projects}
              activeProject={activeProject}
            />
          )}
          {formState === "Add Data" && (
            <AddKnowledgebase
              KB={KB}
              reload={reload}
              setReload={setReload}
              projects={projects}
              activeProject={activeProject}
              setFormState={setFormState}
              activeState={activeState}
              setActiveState={setActiveState}
            />
          )}

          {formState === "Edit Data" && (
            <EditTextData
              KB={KB}
              reload={reload}
              setReload={setReload}
              projects={projects}
              activeProject={activeProject}
              setFormState={setFormState}
              editItem={editItem}
            />
          )}


          
        </div>
      </main>
      <aside className="w-96 overflow-y-auto border-l border-gray-200 lg:block hidden"></aside>
    </div>
  );
}

export default HomeKnowledgebase;
