import React, { useState } from "react";
import Joi from "joi-browser";
import Form from "../../components/Form";
import * as projectService from "../../services/projectService";
import { message } from "antd";

function PineConeComponent({ projects, activeProject, settings, settingReload, setSettingReload }) {
  const [pineConeFields] = useState({
    pineconeIndex:   settings?.vectorDbSettings?.pineconeSettings.pineconeIndex,
    pineconeEnvironment:   settings?.vectorDbSettings?.pineconeSettings.pineconeEnvironment,
    pineconeKey:   settings?.vectorDbSettings?.pineconeSettings.pineconeKey
    ,
  });


  const [loading, setLoading] = useState(false);

  const [errorMessageZohoCRM] = useState({});

  const templateZohoCRM = [
    {
      name: "pineconeIndex",
      label: "Pinecone Index",
      type: "text",
      style: "",
    },
    {
      name: "pineconeEnvironment",
      label: "Pinecone Environment",
      type: "text",
      style: "",
    },
    {
      name: "pineconeKey",
      label: "Pinecone Key",
      type: "password",
      style: "",
    },
  ];

  const buttonInfo = {
    label: "Connect Pinecone",
    style: "entire-width",
  };

  const schemaforPinecone = {
    pineconeIndex: Joi.string().max(5000).required().label("Pinecone Index"),
    pineconeEnvironment: Joi.string()
      .max(5000)
      .required()
      .label("Pinecone Environment"),
    pineconeKey: Joi.required().label("Pinecone Key"),
  };

  const handlePineConnection = async (data) => {
    setLoading(true);

    let newData = {
      pineconeSettings: {
        pineconeIndex: data.pineconeIndex,
        pineconeEnvironment: data.pineconeEnvironment,
        pineconeKey: data.pineconeEnvironment,
      },
    };

    try {
      const response = await projectService.updateProject(
        projects[activeProject]._id,
        { vectorDbSettings: newData }
      );
      if (response.status === 200) {
        message.success("Changes saved");
        setLoading(false);
        setSettingReload(!settingReload)
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false); // Ensure setLoading is defined and used correctly
        message.error(ex.response.data.msg);
      } else {
        setLoading(false);
        console.error("An unexpected error occurred:", ex);
      }
    }
  };

  return (
    <div>
      <main className="lg:pl-22 bg-slate-50 h-screen">
        <div className="xl:pr-96 bg-slate-50">
          <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-slate-50">
            <div className="divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-10">
              <div className="px-4 py-5 sm:px-6">Configure your Pinecone</div>

              <div className="px-64 pb-16">
                <Form
                  errorMessage={errorMessageZohoCRM}
                  inputField={pineConeFields}
                  template={templateZohoCRM}
                  schema={schemaforPinecone}
                  buttonInfo={buttonInfo}
                  onSubmit={handlePineConnection}
                  loading={loading}
                  setLoading={setLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default PineConeComponent;
