import React, { useState, useEffect } from "react";
import { Checkbox, CheckboxField, CheckboxGroup } from "../../common/checkbox";
import { Description, Label } from "../../common//fieldset";
import { Button } from "../../common/button";
import * as projectService from "../../services/projectService";
import { message } from "antd";
function GHLCRM({projects, activeProject, settings, reload, setReload} ) {

  const [crmSearchEnabled, setCRMSearchEnabled] = useState(
    settings?.RAGSettings?.crmSearchEnabled && settings?.RAGSettings?.defaultCRM === "GHLCRM"
  );
  


  const handleSave = async () => {
    const data = {
      welcomeMessages: settings.RAGSettings.welcomeMessages[0],
      addQuestionsToChunks: settings.RAGSettings.addQuestionsToChunks,
      crmSearchEnabled: crmSearchEnabled,
      defaultCRM:"GHLCRM",
      queryRewriteEnabled: settings.RAGSettings.queryRewriteEnabled,
      workflowEnabled: settings.RAGSettings.workflowEnabled
    };
    const response = await projectService.updateProject(
      projects[activeProject]._id,
      { RAGSettings: data }
    );
    if (response.status === 200) {
      message.success("Changes saved");
      setReload(!reload);
    }
  };
  

  const handleGHLContactsSearch =()=>{
    setCRMSearchEnabled(!crmSearchEnabled)
    message.info("Please click save to persist this configuration")
  }


    return (
        <div>
             <main className="lg:pl-22 bg-slate-50 h-screen">
          <div className="xl:pr-96 bg-slate-50">
            <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-slate-50">
              <div className="divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-10">
                <div className="px-4 py-5 sm:px-6">
                  Configure your GoHigh Level Configuration
                </div>
                <div className="p-5">
                  <CheckboxGroup>
                    <CheckboxField>
                    <Checkbox name="ghl_crm_search"  onChange={handleGHLContactsSearch} value={crmSearchEnabled} checked={crmSearchEnabled} />
                      <Label>Go HighLevel contacts search</Label>
                      <Description>
                      Turn on this feature to automatically use the first name of individuals from your GHL CRM contacts on an inbound or outbound call. Currently available only for the voice app
                      </Description>
                    </CheckboxField>
                    {/* <CheckboxField>
                      <Checkbox name="allow_embedding" />
                      <Label>Make Appointments</Label>
                      <Description>
                      Turn on this feature to receive customers' appointment booking requests by call. Check availability on GoHighLevel, make a booking if available, and if not, suggest alternative booking times for customers.
                      </Description>
                    </CheckboxField> */}
                  </CheckboxGroup>
                </div>
                <div className="px-4 py-4 sm:px-6 flex justify-end">
                <Button onClick={handleSave}>Save</Button>
                </div>
              </div>
            </div>
            </div>
          </main>
            
        </div>
    );
}

export default GHLCRM;