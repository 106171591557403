import http from "./httpService";
const apiEndpoint = "/campaigns";

export function getAllCalls(projectId) {
  return http.get(apiEndpoint + "/getAllCampaigns" + "/" + projectId);
}

export function addToCampaign(projectId, data) {
  return http.post(apiEndpoint + "/" + "createCampaign" + "/" + projectId, 
    data,
);
}


