import React from "react";
import { Slider } from "antd";

function SliderComponent({
  defaultValue,
  min,
  max,
  onChange,
  value,
  name,
  step,
}) {
  const handleSliderChange = (value) => {
    onChange({ currentTarget: { value, name: name } });
  };

  return (
    <div className="mb-12">
      <Slider
        name={name}
        onChange={handleSliderChange}
        // defaultValue={defaultValue}
        value={value}
        min={min}
        max={max}
        step={step}
        tooltip={{
          open: false,
          placement: "bottom",
        }}
      />
    </div>
  );
}

export default SliderComponent;
