import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "../../components/Form";
import { Button } from "../../common/button";
import * as projectService from "../../services/projectService";
import { message } from "antd";

function LLMSettings({ projects, activeProject, settings }) {
  const [reload, setReload] = useState(false);

  const [inputField, setInputField] = useState({
    gptResponseModel: settings?.gptResponseModel || "",
    temperature: settings?.temperature || "",
    maxOutputTokens: settings?.maxOutputTokens || "",
  });

  useEffect(() => {
    if (settings) {
      setInputField((prevState) => {
        const newState = {
          gptResponseModel: settings.gptResponseModel,
          maxOutputTokens: settings.maxOutputTokens,
          temperature: settings.temperature,
        };

        return newState;
      });
      setReload(!reload);
    }
  }, [settings]);

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const template = [
    {
      name: "gptResponseModel",
      label: "Model",
      type: "select",
      options: [
        // { id: 1, value: "gpt-3.5-turbo", label: "OpenAI GPT 3.5 Turbo" },
        { id: 2, value: "superdash-35-turbo", label: "GPT 3.5 Turbo" },
        // { id: 3, value: "gpt-4o", label: "GPT 4 Omni" },
        { id: 4, value: "groq/llama3-8b-8192", label: "LLaMA3 8b" },
        { id: 5, value: "groq/llama3-70b-8192", label: "LLaMA3 70b" },
        { id: 6, value: "groq/mixtral-8x7b-32768", label: "Mixtral 8x7b" },
        { id: 7, value: "groq/gemma-7b-it", label: "Gemma 7b" },
        // { id: 8, value: "gpt-4-turbo", label: "OpenAI GPT 4 Turbo" }
      ],
    },

    {
      name: "temperature",
      label: "Temperature",
      //   description: "Only enable it if Auth is required",
      type: "slider",
      min: 0,
      max: 1,
      step: 0.1,
      // defaultValue: settings?settings.temperature:0,
    },

    {
      name: "maxOutputTokens",
      label: "Maximum Output token",
      type: "slider",
      min: 100,
      max: 1000,

      // defaultValue: settings?settings.maxOutputTokens:250,
    },
  ];

  const buttonInfo = { label: "Save", style: "right-side" };

  const schema = {
    gptResponseModel: Joi.string().required().label("Transcription Language"),
    maxOutputTokens: Joi.label("Maximum output tokens"),
    temperature: Joi.label("Temperature"),
  };

  const handleCreate = async (data) => {
    setLoading(true);
    const response = await projectService.updateProject(
      projects[activeProject]._id,
      data
    );
    if (response.status === 200) {
      message.success("Changes saved");
      setLoading(false);
      setReload(!reload);
    }
  };

  return (
    <div>
      <Form
        key={JSON.stringify(inputField)}
        errorMessage={errorMessage}
        inputField={inputField}
        template={template}
        schema={schema}
        onSubmit={handleCreate}
        loading={loading}
        setLoading={setLoading}
        // getValue ={getInputValue}
        buttonInfo={buttonInfo}
      />
    </div>
  );
}

export default LLMSettings;
