import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import lottie from "lottie-web";
import success from "../../animation/succesful.json";
import { Button } from "../../common/button";
import * as thirdpartyService from "../../services/thirdpartyService";
import { message} from 'antd';

function ThirdPartAuthSuccess(props) {
  const container = useRef(null);

  // Using navigate if needed later, since it's declared
  const navigate = useNavigate();

  useEffect(() => {
    let anim;
    if (container.current) {
      anim = lottie.loadAnimation({
        container: container.current, 
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: success, 
      });
    }

    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code') || "";
    const locationParam = queryParams.get('location') || "";
    const accountsServer = queryParams.get('accounts-server') || "";
    const projectId = localStorage.getItem('projectId') || "";
    const type = localStorage.getItem("authCategory") || "";
    

    const checkTokenAsync = async () => {
      try {
        const response= await thirdpartyService.checkToken(projectId, code, locationParam, accountsServer, type);
        if(response.status===200)
        message.success("Authentication succesful")
       } catch (ex) {
         if (ex.response && ex.response.status >= 400 && ex.response.status < 500 ) {
           message.error(ex.response.data.msg);
   
         }
       }
    };
  
    checkTokenAsync();


    return () => {
      if (anim) {
        anim.destroy();
      }
    };
  }, []); //
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div ref={container}></div>
      <Button onClick={() => navigate("/")}>Take me home</Button>
    </div>
  );
}

export default ThirdPartAuthSuccess;
