import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "../../components/Form";
import { message } from "antd";
import * as knowledgeService from "../../services/knowledgebaseService";


function EditTextData({ editItem, KB, setFormState, setReload, reload }) {
  let [count, setCount] = useState(1);
  const [key, setKey] = useState("");

  const [inputField] = useState({
    content: editItem.value.data,
  });

  const [errorMessage, setErrorMessage] = useState({});

  const [loading, setLoading] = useState(false);

  // Dynamically generate the template based on inputFields
  const template = [
    {
      name: "content",
      label: "Edit your content here",
      type: "textArea",
      style: { height: "300px" }
    },
  ];

  // Joi schema for dynamic validation
  const schema = {
    content: Joi.string()
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
  };

  const handleSubmit = async (data) => {
    let result;
    message.info("Please wait while we update your KB")


    try {
      let serviceToCall;

      serviceToCall = knowledgeService.updateKB;
      result = {
        label: KB.label,
        projectId: KB.project,
        knowledgeBasePatchObject: {
          update: [
            {
              key: editItem.key,
              _id: editItem._id,
              value: {
                data: data.content,
              },
            },
          ],
        },
      };

      const res = await serviceToCall(result);
      if (res.status === 200) {
        message.success("Your update is successful");
        setReload(!reload)
        setFormState("Existing Text Data");
      }
    } catch (ex) {
      setLoading(false);
      if (ex.response && ex.response.status === 400) {
        message.error(ex.response.data);
      } else {
        // Handle other errors or invalid hostname error
        message.error(ex.message || "An unexpected error occurred.");
      }
    }
  };
  // const output = Object.values(data)
  //   .filter((value) => value !== "")
  //   .map((value) => ({ value: { data: value } }));

  // const finalData = {
  //   label: KB.label,
  //   projectId: projects[activeProject]._id,
  //   knowledgeBasePatchObject: {
  //     add: output,
  //   },
  // };

  // finalData.paragraphs = output;

  // try {
  //   const response = await knowledgeService.updateKB(finalData);
  //   if (response.status === 200) {
  //     setFormState("Existing Text Data");
  //     setReload(!reload);
  //   }
  // } catch (ex) {
  //   if (
  //     ex.response &&
  //     ex.response.status >= 400 &&
  //     ex.response.status < 500
  //   ) {
  //     setLoading(false);

  //     message.error(ex.response.data.msg);
  //     return;
  //   }
  // }





  return (
    <div className="overflow-hidden rounded-md bg-white px-6 py-4 shadow">
      <Form
        errorMessage={errorMessage}
        inputField={inputField}
        template={template}
        schema={schema}
        buttonInfo={{
          label: "Update info ",
          style: "right-side",
          class:
            "w-full inline-flex items-center justify-center rounded-lg border text-base font-semibold px-4 py-2 text-white bg-zinc-900 focus:outline-none",
        }}
        onSubmit={handleSubmit}
        loading={loading}
        setLoading={setLoading}
      />
    </div>
  );
}

export default EditTextData;
