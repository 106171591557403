import React, { useState, useEffect } from "react";
import * as humanAgentService from "../../../services/humanAgentService";
import TransferMessage from "./TransferMessage";
import ExistingAgents from "./ExistingAgents";
import EditHumanAgent from "./EditHumanAgent";

function Configuration({ projects, activeProject, settings }) {
  const [humanagentsInfo, setHumanAgentsInfo] = useState({});

  const [formState, setFormState] = useState("view");
  const [item, setItem] = useState({});
  const [reload, setReload] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await humanAgentService.getAllAgents(
          projects[activeProject]._id
        );

        setHumanAgentsInfo(response.data); // Assuming response.data is the correct data format
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [projects, activeProject, reload]); // Adding dependencies



  if (formState === "view")
    return (
      <div>
        <TransferMessage
          projects={projects}
          activeProject={activeProject}
          humanagentsInfo={humanagentsInfo}
          setReload={setReload}
          reload={reload}
          settings={settings}
        />
        <ExistingAgents
          projects={projects}
          activeProject={activeProject}
          humanagentsInfo={humanagentsInfo}
          setItem={setItem}
          setFormState={setFormState}
          item={item}
          setReload={setReload}
          reload={reload}
        />
      </div>
    );

  if (formState === "edit")
    return (
      <div>
        <EditHumanAgent
          item={item}
          projects={projects}
          activeProject={activeProject}
          setFormState={setFormState}
          reload={reload}
          setReload={setReload}
        
        />
      </div>
    );
}

export default Configuration;
