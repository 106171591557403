import React, { useState, useEffect } from "react";
import InputComponent from "./InputComponent.jsx";
import Joi from "joi-browser";
import loadingIcon from "../images/loading.svg";
import { Button } from "../common/button.jsx";

function Form({
  inputField,
  errorMessage,
  template,
  schema,
  buttonInfo,
  onSubmit,
  options,
  getValue,
  setErrorState,
  // datainParent,
  setLoading,
  loading,
  emptyState,
  setEmptyState,
  formTitle,
  handleSecondary,
  step
}) {
  const [data, setData] = useState(inputField);
  const [error, setError] = useState({ errorMessage });
  const [schemaCheck] = useState(schema);

  // useEffect(() => {
  //   if (datainParent) setData(datainParent);
  // }, [datainParent]);

  useEffect(() => {
    if (emptyState === true) {
      setData(inputField);
      setError({ errorMessage });
      setEmptyState(false);
    }
  }, [emptyState]);

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schemaCheck, options);

    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaNew = { [name]: schemaCheck[name] };
    const { error } = Joi.validate(obj, schemaNew);
    return error ? error.details[0].message : null;
  };

  const handleChange = ({ currentTarget: input, toggleValue }) => {
    let newData = { ...data };
    let newError = { ...error };



    const targetValue = toggleValue !== undefined ? toggleValue : input.value;
    const errorMessage = validateProperty(input);
    if (errorMessage) newError[input.name] = errorMessage;
    else delete newError[input.name];

    newData[input.name] = targetValue;

    if (getValue) getValue(newData);
    setData(newData);
    setError(newError);
    if (setErrorState) validate();
  };

  const onchangeInput = (val, index) => {
    let newData = { ...data };
    newData.options[index] = val.target.value;
    if (getValue) getValue(newData);
    setData(newData);
  };

  // const handleSelect = (selectedOption) => {
  //   let newData = { ...data };
  //   newData.matchType = selectedOption.value;
  //   setData(newData);
  // };

  const getButtonClasses = (style) => {
    let classes = style === "normal" ? "btnn " : "btnn btnn-strech ";

    return (classes += validate() ? "btnn-disable " : "btnn-primary");
  };

  const handleSubmit = (e) => {
    setLoading(true);

    e.preventDefault();
    const errors = validate();

    setError(errors || {});

    if (errors) return;

    onSubmit(data);
  };

  // console.log(validate() )

  return (
    <form className="w-full  space-y-8">
      <h3 className="text-lg font-semibold tracking-[-0.015em] text-zinc-950 sm:text-base dark:text-white">
        {formTitle}
      </h3>

      {template.map((item, index) => (
        <InputComponent
          key={index}
          name={item.name}
          value={data[item.name]}
          label={item.label}
          type={item.type}
          placeholder={item.placeholder}
          onChange={handleChange}
          error={error[item.name]}
          style={item.style}
          options={item.options}
          arrayLength={item.arrayLength}
          onchangeInput={onchangeInput}
          description={item.description}
          min={item.min}
          max={item.max}
          defaultValue={item.defaultValue}
          step={item.step}
        />
      ))}

{buttonInfo && (
  buttonInfo.buttons === "2" ? (
    <div className="flex justify-between w-full space-x-2">
      {/* Secondary Button */}
      <Button
        onClick={handleSecondary}
        outline
        className="w-1/2 inline-flex items-center justify-center rounded-lg text-base font-semibold px-4 py-2 text-white hover:bg-gray-900 focus:outline-none"
      >
        {buttonInfo.secondaryLabel}
      </Button>
      {/* Primary Button */}
      <Button
        loading={loading}
        onClick={handleSubmit}
        disabled={validate() || loading}
        className="w-1/2 inline-flex items-center justify-center rounded-lg border border-gray-300 text-base font-semibold px-4 py-2 text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none"
      >
        {buttonInfo.primaryLabel}
      </Button>
    </div>
  ) : buttonInfo.style === "entire-width" ? (
    <Button
      loading={loading}
      onClick={handleSubmit}
      disabled={validate() || loading}
      className="w-full inline-flex items-center justify-center rounded-lg border text-base font-semibold px-4 py-2 text-white bg-zinc-900 focus:outline-none"
    >
      {buttonInfo.label}
    </Button>
  ) : (
    buttonInfo.style === "right-side" && (
      <div className="flex justify-end">
        <Button
          onClick={handleSubmit}
          disabled={validate() || loading}
          className="inline-flex items-center justify-center rounded-lg border text-base font-semibold px-4 py-2 text-white bg-zinc-900 focus:outline-none"
        >
             {buttonInfo.label}
        </Button>
      </div>
    )
  )
)}


      

      {/* <button className="w-full inline-flex items-center justify-center rounded-lg border text-base font-semibold px-4 py-2 text-white bg-zinc-900 focus:outline-none" type="button">
                  Get started
                </button> */}
      {/* <p className="text-base text-zinc-500 sm:text-sm dark:text-zinc-400">
                  Don’t have an account? <a href="#" className="font-semibold text-zinc-950 hover:text-zinc-700 dark:text-white dark:hover:text-zinc-300">Sign up</a>
                </p> */}
    </form>
  );
}

export default Form;
