import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../common/table";
import { Button } from "../../../common/button";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../../../common/dropdown";
import { EllipsisHorizontalIcon } from "@heroicons/react/16/solid";
import {
  Alert,
  AlertActions,
  AlertBody,
  AlertDescription,
  AlertTitle,
} from "../../../common/alert";
import * as humanAgentService from "../../../services/humanAgentService";
import * as customAgentService from "../../../services/customActions";
import { message } from "antd";

function ExistingEndCallConditions({
  projects,
  activeProject,
  allEndTriggers,
  setItem,
  setFormState,
  item,
  setReload,
  reload,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleEdit = (item) => {
    setFormState("edit");
    setItem(item);
  };

  const showDeleteModal = (item) => {
    setIsOpen(true);
    setItem(item);
  };

  const handleDeleteConfirmation = async () => {
    try {
      const response = await customAgentService.deleteSMSAction(
        projects[activeProject]._id,
        item._id
      );

      if (response.status === 200) {
        message.success("This item is successfully deleted");
        setIsOpen(false);
        setReload(!reload);
        setFormState("view");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error("Request failed");
    }
  };

  return (
    <div>
      <div className="divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-10">
        <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
          Existing Triggers
        </div>
        <div>
          {allEndTriggers && allEndTriggers.length > 0 && (
            <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)] p-5">
              <TableHead>
                <TableRow>
                  <TableHeader>SMS Action Name</TableHeader>

                  <TableHeader>Action</TableHeader>
                </TableRow>
              </TableHead>

              <TableBody>
                {allEndTriggers &&
                  allEndTriggers.length > 0 &&
                  allEndTriggers.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className="font-medium">
                        {item.actionName}
                      </TableCell>
            

                      <TableCell>
                        <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                          <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                            <Dropdown>
                              <DropdownButton plain aria-label="More options">
                                <EllipsisHorizontalIcon />
                              </DropdownButton>
                              <DropdownMenu anchor="bottom end">
                                <DropdownItem
                                  onClick={() => showDeleteModal(item)}
                                >
                                  Delete
                                </DropdownItem>

                                <DropdownItem onClick={() => handleEdit(item)}>
                                  Edit
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
          <Alert open={isOpen} onClose={() => setIsOpen(false)} size="sm">
            <AlertTitle>Delete SMS Trigger</AlertTitle>
            <AlertDescription>
              Once deleted, this information will be removed from our system,
              and no further actions will be taken regarding this condition.
            </AlertDescription>
            <AlertBody style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              ></div>
            </AlertBody>
            <AlertActions>
              <Button plain onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
              <Button onClick={() => handleDeleteConfirmation()}>OK</Button>
            </AlertActions>
          </Alert>
        </div>
      </div>
    </div>
  );
}

export default ExistingEndCallConditions;
