import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Badge } from './badge'; 

const commands = [
  { name: "knowledgebase", color: "blue" },
  { name: "memory", color: "green" },
  { name: "follow up questions", color: "red" },
  { name: "welcome message", color: "fuchsia" },
  // Add more commands as needed
];

function NotionEditor() {
  const [showCommands, setShowCommands] = useState(false);
  const [ignoreSlash, setIgnoreSlash] = useState(false);
  const editorRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Focus the editor on mount
    editorRef.current.focus();
  }, []);

  const handleKeyUp = (event) => {
    if (event.key === '/' && !showCommands) {
      setShowCommands(true);
      setIgnoreSlash(true); // Set the flag to true when '/' is pressed
    } else {
      setIgnoreSlash(false); // Any other key will reset the flag
    }
  };

  const insertBadge = (commandName, color) => {
    const editor = editorRef.current;
    if (!editor) return;
  
    // Save the current selection
    const selection = window.getSelection();
    if (!selection.rangeCount) return;
  
    // Start a batch of updates
    ReactDOM.unstable_batchedUpdates(() => {
      // Remove all ranges to reset the selection
      selection.removeAllRanges();
  
      if (ignoreSlash) {
        // Find and remove the '/' character from the text node
        const nodes = editor.childNodes;
        for (let i = nodes.length - 1; i >= 0; i--) {
          const node = nodes[i];
          if (node.nodeType === Node.TEXT_NODE && node.nodeValue.includes('/')) {
            // This assumes that the '/' is at the end of the node value.
            node.nodeValue = node.nodeValue.replace('/', '');
            break;
          }
        }
  
        setIgnoreSlash(false); // Reset the flag after removing the slash
      }
  
      // Create a new range for the badge
      const range = document.createRange();
  
      // Create the badge element to be inserted
      const badgeElement = document.createElement('span');
      ReactDOM.render(<Badge name={commandName} color={color} />, badgeElement);
  
      // Insert the badge element
      range.setStart(editor, editor.childNodes.length);
      range.collapse(true);
      range.insertNode(badgeElement);
  
      // Create a new text node for typing after the badge
      const textNode = document.createTextNode('\u00A0'); // Non-breaking space
      editor.appendChild(textNode);
  
      // Update the range to the end of the new text node
      range.setStartAfter(textNode);
      range.collapse(true);
  
      // Apply the new range
      selection.addRange(range);
  
      // Scroll to the end of the editor
      editor.scrollTop = editor.scrollHeight;
  
      setShowCommands(false);
    });
  
    // Focus the editor after the updates
    editor.focus();
  };
  

  

  const handleClickOutsideBadge = (event) => {
    const selection = window.getSelection();
    // If the current selection is outside the badge, hide the commands
    if (!event.target.classList.contains('badge')) {
      setShowCommands(false);
    }
  };

  return (
    <div className="relative" onClick={handleClickOutsideBadge}>
      <div
        contentEditable
        ref={editorRef}
        onKeyUp={handleKeyUp}
        className="w-full p-2 border border-gray-300"
        style={{ minHeight: '500px' }}
      />
      {showCommands && (
        <div
          ref={dropdownRef}
          className="absolute z-10 mt-1 w-full bg-white border border-gray-200"
          style={{
            left: editorRef.current?.offsetLeft || 0,
            top: editorRef.current?.offsetTop + editorRef.current?.offsetHeight || 0,
          }}
        >
          {commands.map((cmd, idx) => (
            <div
              key={idx}
              onClick={() => insertBadge(cmd.name, cmd.color)}
              className="p-2 hover:bg-gray-100 cursor-pointer"
            >
              {cmd.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default NotionEditor;
