import React from "react";
import { ErrorMessage, Description, Field, Label } from "../common/fieldset";
import { Input } from "../common/input";
import { Textarea } from "../common/textarea";
import { Select } from "../common/select";
import { Switch, SwitchField } from "../common/switch";
import SliderComponent from "../common/Slider";

const InputComponent = ({
  name,
  label,
  value,
  error,
  onChange,
  type,
  style,
  placeholder,
  options,
  width,
  customClass,
  description,
  min,
  max,
  defaultValue,
  step,
}) => {
  return (
    <div>
      {(type === "text" ||
        type === "number" ||
        type === "password" ||
        type === "color" ||
        type === "email") && (
        <>
          <Field>
            <Label>{label}</Label>
            <Description>{description}</Description>
            <Input placeholder={placeholder} type={type} name={name} value={value} onChange={onChange} />
            {(error != {} || undefined) && <ErrorMessage>{error}</ErrorMessage>}
          </Field>
        </>
      )}
      {type === "textArea" && (
        <Field>
          <Label>{label}</Label>
          <Description>{description}</Description>
          <Textarea
            style={{ height: style.height }}
            name={name}
            value={value}
            onChange={onChange}
          />
          {(error != {} || undefined) && <ErrorMessage>{error}</ErrorMessage>}
        </Field>
      )}
      {type === "select" && (
        <Field>
          <Label>{label}</Label>
          <Description>{description}</Description>
          <Select name={name} onChange={onChange} value={value} defaultValue="">
            {/* <option value={value}>Select an option</option> */}
            {options.map((option) => (
              <option key={option.id} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          {(error != {} || undefined) && <ErrorMessage>{error}</ErrorMessage>}
        </Field>
      )}

{type === "toggle" && (
  <SwitchField>
    <Label>{label}</Label>
    <Description>{description}</Description>
    <Switch
      checked={value} 
      name={name}
      onChange={(isActive) => {
        onChange({
          currentTarget: {
            name: name,
            value: isActive,
          },
          toggleValue: isActive,
        });
      }}
    />
  </SwitchField>
)}


      {type === "slider" && (
        <div>
          <div className="flex items-center justify-between mb-2">
            <label className="text-sm font-medium text-gray-700">
              {label}:
            </label>
            <span className="text-sm font-semibold bg-slate-200 rounded-lg p-1 text-black px-2 py-1">
              {value}
            </span>
          </div>
          <p className="text-xs text-gray-500 mb-4">{description}</p>
          <SliderComponent
            min={min}
            max={max}
            onChange={onChange}
            value={value}
            name={name}
            step={step}
          />
        </div>
      )}
    </div>
  );
};

export default InputComponent;
