import React, { useState } from "react";
import { Checkbox, CheckboxField, CheckboxGroup } from "../../common/checkbox";
import Joi from "joi-browser";
import { Button } from "../../common/button";
import { Description, Label } from "../../common/fieldset";
import { Switch, SwitchField } from "../../common/switch";
import Form from "../../components/Form";
import * as thirdPartyServices from "../../services/thirdpartyService"
import { message } from "antd";

function Recording({projects, activeProject}) {
  const [inputField] = useState({
    eventName: "",
    endpointUrl: "",
  });

  const [recording, setRecording] = useState(false);
  const [disclaimer, setDisclaimer] = useState(false);

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const template = [
    disclaimer && {
      name: "initialMessageText",
      label: "Disclaimer text",
      description:
        "Write text here that will be converted into a voice note for the Disclaimer. Please fill in this field or provide a disclaimer voice URL, not both",
      type: "textArea",
      style: { height: "150px" },
    },

    disclaimer && {
      name: "initialMessageURL",
      label: "Disclaimer Voice URL",
      description:
        "Paste Voice URL here for the Disclaimer. Please fill in this field or provide a Disclaimer text above, not both",
      type: "text",
    },
  ];

  const buttonInfo = { label: "Submit", style: "right-side" };

  const schema = {
    initialMessageText: Joi.string().required().label("event name"),
    initialMessageURL: Joi.string().required().label("endpointUrl"),
  };

  const handleRecording =  () => {};

  const handleChange = async (value) => {
    if (value === "recording") setRecording(!recording);

    else setDisclaimer(!disclaimer);

    let telephonySettings = {
        telephonySettings: {
          telephonyProvider: "twilio",
          twilioSettings: {
            recordingEnabled : value === "recording"?!recording: recording,
            initialMessageSettings:{
                initialMessageText:121,
                initialMessageURL: 121
            }
          }
        }
      };

    try {
        const response = await thirdPartyServices.thirdpartyUpdate(telephonySettings, projects[activeProject]._id);
        if (response.status === 200) {
          message.success("Changes saved");
          setLoading(false)
        }
      } catch (ex) {
        if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
          setLoading(false);  // Ensure setLoading is defined and used correctly
          message.error(ex.response.data.msg);
          setLoading(false)
        }
      }
  };

  {
    return (
      <div>
        <main className="lg:pl-22 bg-slate-50 h-screen">
          <div className="xl:pr-96 bg-slate-50">
            <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-slate-50">
              <div className="divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-10">
                <div className="p-5">
                  <SwitchField>
                    <Label>Recording</Label>
                    <Description>
                      {" "}
                      All audio recordings are now conveniently attached to
                      their respective discussion threads for easy reference and
                      review. You have the option to disable this feature if you
                      prefer not to record any calls.
                    </Description>
                    <Switch
                      name="recording"
                      value={recording}
                      onChange={() => handleChange("recording")}
                    />
                  </SwitchField>

                  <SwitchField className="pt-5">
                    <Label>Play Disclaimer</Label>
                    <Description>
                      {" "}
                      This toggle enables or disables the automatic playback of
                      a pre-recorded disclaimer at the beginning of a call or
                      session.
                    </Description>
                    <Switch
                      name="disclaimer"
                      value={disclaimer}
                      onChange={() => handleChange("disclaimer")}
                    />
                  </SwitchField>

                  <Form
                    errorMessage={errorMessage}
                    inputField={inputField}
                    template={template}
                    schema={schema}
                    buttonInfo={buttonInfo}
                    onSubmit={handleRecording}
                    loading={loading}
                    setLoading={setLoading}
                    // getValue ={getInputValue}
                  />
                </div>
             
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default Recording;
